import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Languages } from 'reach/reach.configuration';
import { IDeployedPackageList } from 'reach/reach.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {

  @Output() viewAll = new EventEmitter();
  @Output() collapseTab = new EventEmitter();
  @Input() deployedPackageData: IDeployedPackageList[];
  @Input() collapseData;
  public Languages = Languages;
  public searchPackage: string;
  constructor(
    private router: Router
  ) { }
  ngOnInit() {
  }

  onCollapseTab(type: string) {
    this.collapseTab.emit(type);
  }

  onViewAll(type: string) {
    this.viewAll.emit(type);
  }

  packageDetails(item) {
    const id = item.packageId;
    this.router.navigate(['/home/packages/package-history/', {'packageId': id}]);
  }
}
