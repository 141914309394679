import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FilterService } from '../services/utilities/filter.service';
import { AppConfiguration, Languages } from 'reach/reach.configuration';
import { NavigationEnd, Router } from '@angular/router';
import { ClientService } from '../services/client.service';
// import { StateMapper } from '@reach/common/map-module/map/data/state-mapper';
import { IFilter } from '@reach/reach.interface';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';


@Component({
    selector: 'app-bar-chart-toolbar',
    templateUrl: './bar-chart-toolbar.component.html',
    styleUrls: ['./bar-chart-toolbar.component.scss']
})
export class BarChartToolbarComponent implements OnInit, OnChanges, OnDestroy {
    customers: String[];
    models: String[];
    periods: String[];
    locations: Array<string>;
    selectedCustomers: Array<any>;
    selectedLocations: Array<any>;
    selectedModels: Array<any>;
    selectedPeriod: string;
    updatedFilter: IFilter;
    // locationMapper = StateMapper.stateMap;
    selectedPeriodCtrl: FormControl = new FormControl();
    startDate = {
        formCtrl: new FormControl(),
        max: moment().subtract(1, 'day').format(),
        min: moment().subtract(366 + 365, 'day').format()
    };
    endDate = {
        formCtrl: new FormControl(),
        max: moment().subtract(1, 'day').format(),
        min: moment().subtract(366 + 365, 'day').format()
    };
    formattedStartDate;
    formattedEndDate;

    @Input() isBack = true;
    @Input() showCustomer = true;
    @Input() showLocation = true;
    @Input() showModel = true;
    @Input() showPeriod = true;
    @Input() isOnlyClear = false;
    subscriptions: Array<Subscription> = [];
    public Languages = Languages;
    public chipsLimit = 1;
    public countLimit = 0;
    public currentRoute;
    public isPeriodEnabled = false;
    public isFilterEnabled = true;
    public isDateRangeApplied = false;
    private filterSubscription: Subscription;

    constructor(private filterService: FilterService, private router: Router, public clientService: ClientService) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = {
                    full: router.url,
                    base: `/${router.url.split('/')[1]}`
                };
                this.isPeriodEnabled = (AppConfiguration.global.filter.disablePeriodsOnRoutes.indexOf(this.currentRoute.full)
                    === -1);
                this.isFilterEnabled = (AppConfiguration.global.filter.disableFilterOnRoutes.indexOf(this.currentRoute.full)
                    === -1);
            }
        });
    }

    ngOnInit() {
        this.filterSubscription = this.filterService.getSelectedFilter().subscribe(updatedFilter => {
            this.selectedCustomers = this.selectedModels = this.selectedLocations = [];
            this.selectedPeriod = '';
            this.initCustomers(updatedFilter);
            this.initModels(updatedFilter);
            this.initLocations(updatedFilter);
            this.initPeriod(updatedFilter);
            this.formattedStartDate = moment(this.startDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0]);
            this.formattedEndDate = moment(this.endDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0]);
            if (this.formattedStartDate !== updatedFilter.dateRange.start || this.formattedEndDate !== updatedFilter.dateRange.end) {
                this.initDate(updatedFilter);
            }
            if (updatedFilter.dateRange.start && updatedFilter.dateRange.end) {
                this.isDateRangeApplied = true;
            }
            this.updatedFilter = updatedFilter;
        });
        this.subscriptions.push(this.startDate.formCtrl.valueChanges.subscribe((value) => {
            if (!value) {
                this.endDate.formCtrl.disable();
            } else {
                this.endDate.formCtrl.enable();
            }
            if (this.updatedFilter.dateRange.start
                !== moment(this.startDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0])) {
                this.publishDateChanges();
            }
            if (!this.dateRangeIsValid()) {
                this.endDate.formCtrl.setValue(null);
            }
        }));
        this.subscriptions.push(this.endDate.formCtrl.valueChanges.subscribe((value) => {
            if (this.updatedFilter.dateRange.end
                !== moment(this.endDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0])) {
                this.publishDateChanges();
            }
        }));
        this.subscriptions.push(this.selectedPeriodCtrl.valueChanges.subscribe(val => {
            this.resetDateRange();
            this.filterService.publishSelectedOption(val, 'period');
        }));
    }

    initModels(updatedFilter: IFilter) {
        this.subscriptions.push(
            this.clientService.modelList$.subscribe((val) => {
                this.models = val.map(model => model.model.slice());
                this.models.sort();
                if (Array.isArray(updatedFilter.model[0])) {
                    this.selectedModels = this.getUpdatedList(updatedFilter.model[0], this.models);
                } else {
                    this.selectedModels = this.getUpdatedList(updatedFilter.model, this.models);
                }
            })
        );
    }

    initLocations(updatedFilter: IFilter) {
        // this.locations = StateMapper.stateList.map((location) => location.code);
        this.locations.sort();
        if (Array.isArray(updatedFilter.location[0])) {
            this.selectedLocations = this.getUpdatedList(updatedFilter.location[0], this.locations);
        } else {
            this.selectedLocations = this.getUpdatedList(updatedFilter.location, this.locations);
        }
    }

    initCustomers(updatedFilter: IFilter) {
        this.subscriptions.push(
            this.clientService.clientDetails$.subscribe((val) => {
                this.customers = val.activeCustomerList.slice();
                this.customers.sort();
                this.selectedCustomers = this.getUpdatedList(updatedFilter.customer, this.customers);
            })
        );
    }

    initPeriod(updatedFilter: IFilter) {
        this.periods = AppConfiguration.global.periods.values;
        this.selectedPeriodCtrl.setValue(updatedFilter.period, { emitEvent: false, onlySelf: true });
    }

    ngOnDestroy() {
        this.filterSubscription.unsubscribe();
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    ngOnChanges() {
        this.isPeriodEnabled = this.showPeriod ? true : false;
    }

    applyFiltersList(value, filterType) {
        let valueCopy = value.slice();
        this.filterService.publishSelectedOption(valueCopy, filterType);
    }

    clearAllFilter() {
        this.filterService.publishFilterData(['ALL'], ['ALL'], 'pastthirtydays', ['ALL'], { start: null, end: null });
        this.filterService.triggerClearAll(true);
        this.isDateRangeApplied = false;
        this.resetDateRange();
    }

    getUpdatedList(updatedList, originalList) {
        return (updatedList.length === 0 || updatedList[0] === 'ALL')
            ? originalList.slice() : updatedList.slice();
    }

    resetDateRange() {
        this.startDate.formCtrl.reset();
        this.endDate.formCtrl.reset();
        this.filterService.publishSelectedOption({
            start: null,
            end: null,
        }, 'dateRange');
        this.isDateRangeApplied = false;
    }

    dateRangeIsValid() {
        const startDateValid = new Date(this.startDate.formCtrl.value).getTime();
        const endDateValid = new Date(this.endDate.formCtrl.value).getTime();
        return (this.startDate.formCtrl.value &&
            this.startDate.formCtrl.valid &&
            this.endDate.formCtrl.value &&
            this.endDate.formCtrl.valid && startDateValid <= endDateValid);
    }

    initDate(updatedFilter) {
        this.startDate.formCtrl.patchValue(
            moment(updatedFilter.dateRange.start).toISOString(), { emitEvent: false }
        );
        this.endDate.formCtrl.patchValue(
            moment(updatedFilter.dateRange.end).toISOString(), { emitEvent: false }
        );
    }

    publishDateChanges() {
        if (this.dateRangeIsValid()) {
            // to updated DateRange on Main Component
            this.filterService.publishSelectedOption({
                start: moment(this.startDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0]),
                end: moment(this.endDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0]),
            }, 'dateRange');

            // to apply global filter
            this.filterService.publishFilterData(
                this.updatedFilter.customer,
                this.updatedFilter.model,
                'pastthirtydays',
                this.updatedFilter.location,
                {
                    start: moment(this.startDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0]),
                    end: moment(this.endDate.formCtrl.value).format(AppConfiguration.global.apiTimeFormat[0]),
                });
        }
    }

    enablePeriod() {
        this.resetDateRange();
        this.applyFiltersList('pastthirtydays', 'period');
    }
}
