import { interval } from 'rxjs/internal/observable/interval';
import {
    Component,
    OnChanges,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { Languages, AppConfiguration } from 'reach/reach.configuration';
import {
    IscriptsReports,
    INotesList,
    IUserInfo,
    ISpecificNote,
    INewComment,
    ITask,
    IDeployedPackageList
} from 'reach/reach.interface';
import * as moment from 'moment';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '@reach/common/dialog-module';
import { FileUtilsService, ISasToken, SearchFilterPipe, UserProfileService, ListOperationsService } from '@reach/common/shared-module';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { ScriptsTabService } from './scripts-tab.service';
import { toLower } from 'lodash';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { UserPermissionsService } from 'reach/common/user-permissions-module';

@Component({
    selector: 'app-scripts-tab',
    templateUrl: './scripts-tab.component.html',
    styleUrls: ['./scripts-tab.component.scss'],

    providers: [ScriptsTabService]
})
export class ScriptsTabComponent implements OnInit, OnChanges, OnDestroy {
    public max = Infinity;

    @Input() allTaskData: any[] = [];
    @Input() revolutionList = [];
    @Input() isSingleSelectionCheckbox = false;
    @Input() componentLocation = '';
    @Input() serialNo = '';

    @Input() selectedTabForScripts: number; // coming from revolution-dashboard reading from queryParams
    @Input() isAdmin: boolean;
    @Input() isDialog = false;

    @Input() isFastRealoadEnabled: boolean;

    @Output() updateDataRequest = new EventEmitter<boolean>(false); // alerts revolution-dashboard that a tab has been clicked
    @Output() updateSelectedTab = new EventEmitter<number>();

    public toLower = toLower;
    public searchScript: string;
    public searchReport: string;
    public searchTask: string;
    public checked = false;
    public selectedScripts: any = [];
    public selectedReports: Array<any> = [];
    public completedScripts: Array<any> = [];
    public completedReports: Array<any> = [];
    public scriptsData: IscriptsReports[] = [];
    public reportsData: IscriptsReports[] = [];
    public displayTaskData: ITask[] = [];
    public notesData: INotesList[] = [];
    public specificNotesData: ISpecificNote;
    public Languages = Languages;
    public deviceId;
    public customerName;
    public scriptStatus = ['ideal', 'running', 'complete'];
    public selectedReportNames = [];
    public tasksSelected = false;
    public tasksLoaded = true;
    public noteCreationStatus: Boolean = false;
    public isViewNote = false;
    public newNote = {};
    public userInfo: IUserInfo;
    public loading = false;
    public isEditDelete = false;
    public isValid = false;
    public saveStatus = false;
    public commandList = [];
    public permission = AppConfiguration.permission;
    public collapseStates = {
        // for maintaining the collapsible views in scripts, notes, reorts, (snr) and task tabs.
        snr: {
            hide: false,
            maxItemsInList: 5,
            style: {},
            showViewAll: true,
            rightArrow: false
        },
        task: {
            hide: false,
            maxItemsInList: 5,
            style: {},
            showViewAll: true,
            rightArrow: false
        }
    };

    // DeployedPackageList
    public deployedPackageList: IDeployedPackageList[] = [];
    pushDialogSub: Subscription;
    sasTokenSub: Subscription;
    fileUploadSub: Subscription;
    successUploadSub: Subscription;
    completionData = {};
    public fileTransferStates = [];
    public fileTransferData = [];
    public selectedTasks = [];
    public refreshInterval = AppConfiguration.global.refreshIntervalNormal;
    public deployedPackageSubscription: Subscription;
    private viewAllTasks = false;

    constructor(
        private searchPipe: SearchFilterPipe,
        private scriptService: ScriptsTabService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public userProfileService: UserProfileService,
        public fileUtilsService: FileUtilsService,
        private cdr: ChangeDetectorRef,
        private userPermissionsService: UserPermissionsService,
        private listOperationsService: ListOperationsService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.allTaskData && changes.allTaskData.currentValue) {
            this.displayTaskData = changes.allTaskData.currentValue;
        }
        if (this.isFastRealoadEnabled) {
            this.unsubscribeToPolling();
            this.getDeployedPackageData();
        }
    }

    ngOnInit() {
        // if device ids are from search result page, they will be multiple, so device id will go as default.
        if (typeof this.revolutionList === 'string') {
            this.deviceId = this.revolutionList;
        } else {
            this.deviceId = 'DEFAULT';
        }
        this.notesData = [];
        this.getScriptData();
        this.getReportData();
        this.getNotesList();
        this.getDeployedPackageData();
        // this.userProfileService.getUserInfoService().subscribe(data => {
        //     this.userInfo = data;
        // });
    }

    ngOnDestroy() {
        if (this.pushDialogSub) {
            this.pushDialogSub.unsubscribe();
        }
        if (this.sasTokenSub) {
            this.sasTokenSub.unsubscribe();
        }
        if (this.fileUploadSub) {
            this.fileUploadSub.unsubscribe();
        }
        if (this.successUploadSub) {
            this.successUploadSub.unsubscribe();
        }
        this.cdr.detach();
    }

    unsubscribeToPolling() {
        if (this.deployedPackageSubscription) {
            this.deployedPackageSubscription.unsubscribe();
            this.deployedPackageSubscription = null;
        }
    }

    createAndDownloadBlobFile(body, filename, extension = 'pdf') {
        const blob = new Blob([body]);
        const file = filename.split('.');
        const fileName = `${file[0]}.${extension}`;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    downloadSelectedTasks() {
        this.selectedTasks.forEach(task => {
            this.downloadTask(task);
        });
    }

    downloadTask(item) {
        this.scriptService.downloadTask(item).subscribe(
            res => {
                this.fileUtilsService.downloadTextFile(
                    AppConfiguration.downloadType.link,
                    res.body
                );
            },
            error => {
                const taskItem = this.getDisplayTask(item.id);
            }
        );
    }

    getDisplayTask(id: string) {
        return this.displayTaskData.find(task => {
            return task.id === id;
        });
    }

    getScriptData() {
        this.scriptService
            .getScripts(this.deviceId)
            .pipe(
                map((scripts: Array<any>) => {
                    return scripts.filter(script =>
                        this.userPermissionsService.isPermitted(
                            script.permissionId
                        )
                    );
                })
            )
            .subscribe(res => {
                if (res) {
                    this.scriptsData = res;
                    this.scriptsData.map(value => {
                        value.status = this.scriptStatus[0];
                        value.isSelected = false;
                        if (value.commandActionTimeStamp) {
                            value.commandActionTimeStamp = moment(
                                value.commandActionTimeStamp
                            )
                                .local()
                                .format(
                                    AppConfiguration.global
                                        .fullDateAndTimeFormat
                                );
                        }
                    });
                    this.listOperationsService.applySort(this.scriptsData, 'asc', 'displayName');
                }
            });
    }

    getReportData() {
        this.scriptService
            .getReports(this.deviceId)
            .pipe(
                map((scripts: Array<any>) => {
                    return scripts.filter(script =>
                        this.userPermissionsService.isPermitted(
                            script.permissionId
                        )
                    );
                })
            )
            .subscribe(res => {
                if (res) {
                    this.reportsData = res;
                    this.reportsData.map(value => {
                        value.status = this.scriptStatus[0];
                        value.isSelected = false;
                        value.customerName = this.customerName;
                        value.deviceId = this.deviceId;
                        if (value.commandActionTimeStamp) {
                            value.commandActionTimeStamp = moment(
                                value.commandActionTimeStamp
                            )
                                .local()
                                .format(
                                    AppConfiguration.global
                                        .fullDateAndTimeFormat
                                );
                        }
                    });
                    this.listOperationsService.applySort(this.reportsData, 'asc', 'commandName');
                }
            });
    }

    getDeployedPackageData() {
        this.deployedPackageSubscription = interval(this.refreshInterval).pipe(
            startWith(0),
            switchMap(() => {
                return this.scriptService.getDeployedPackageList(
                    this.deviceId
                );
            })
        ).subscribe(res => {
            if (res) {
                this.deployedPackageList = res;
                this.deployedPackageList.forEach((ele, index) => {
                    if (!ele['packageName']) {
                        this.deployedPackageList[index].packageName = 'NA';
                    }
                    if (!ele['versionNumber']) {
                        this.deployedPackageList[index].versionNumber = 'NA';
                    }
                    this.deployedPackageList[index].deploymentDate = moment(
                        ele.deploymentDate
                    )
                        .local()
                        .format(AppConfiguration.global.fullDateAndTimeFormat);
                });
            }
        });
    }

    onCollapseTab(tabType: string) {
        // if any one is completely collapsed.
        if (
            (!this.collapseStates.snr.hide && this.collapseStates.task.hide) ||
            (this.collapseStates.snr.hide && !this.collapseStates.task.hide)
        ) {
            this.halfCollapse('snr');
            this.halfCollapse('task');
        } else if (
            !this.collapseStates.snr.hide &&
            !this.collapseStates.task.hide
        ) {
            // don't do any thing if both are half collapsed.
        } else {
            // toggle full view
            this.collapseStates[tabType].hide = !this.collapseStates[tabType]
                .hide;
            this.collapseStates[tabType].maxItemsInList = this.max;
        }
    }

    onViewAll(tabType: string) {
        for (let tab in this.collapseStates) {
            if (tabType === tab) {
                this.showFull(tab);
                this.collapseStates[tab].showViewAll = false;
            } else {
                this.collapseStates[tab].hide = true;
                this.collapseStates[tab].maxItemsInList = 5;
                this.collapseStates[tab].showViewAll = false;
                this.collapseStates[tab].rightArrow = true;
            }
        }
    }

    showFull(tab) {
        this.collapseStates[tab].hide = false;
        this.collapseStates[tab].maxItemsInList = this.max;
        this.collapseStates[tab].style = {
            'max-height': '597px',
            'overflow-y': 'scroll',
            'min-height': '500px'
        };
    }

    halfCollapse(tab) {
        this.collapseStates[tab].hide = false;
        this.collapseStates[tab].maxItemsInList = 5;
        this.collapseStates[tab].style = {};
        this.collapseStates[tab].showViewAll = true;
        this.collapseStates[tab].rightArrow = false;
    }

    onSelectScript(evtObj) {
        evtObj.isSelected = !evtObj.isSelected;
        this.scriptsData.forEach(value => {
            if (value.id === evtObj.id) {
                value.isSelected = evtObj.isSelected;
            }
        });

        if (this.isSingleSelectionCheckbox) {
            this.scriptsData.forEach(x => {
                if (x.id !== evtObj.id) {
                    x.isSelected = false;
                }
            });
        }

        this.selectedScripts.push(evtObj);
        this.selectedScripts = this.selectedScripts.filter(v => v.isSelected);
    }

    onRunScript() {
        this.selectedScripts.map(ob => {
            ob.status = this.scriptStatus[1];
            return ob;
        });

        this.scriptsData.map(ob => {
            this.selectedScripts.map(v => {
                if (ob.id === v.id) {
                    ob.status = v.status;
                }
            });
        });
        this.scriptsData.forEach(command => {
            if (command.status === 'running') {
                this.commandList.push(command.agentFunctionName);
            }
        });
        if (this.isSingleSelectionCheckbox) {
            this.formSingleScriptPayload();
        }
        this.scriptService.runCommand(this.selectedScripts, this.isSingleSelectionCheckbox).subscribe(res => {
            // if (res.status === 200) {
            this.scriptsData.map(ob => {
                if (ob.status === 'running') {
                    ob.status = this.scriptStatus[2];
                }
                return ob;
            });

            this.commandList = [];
            setTimeout(() => {
                this.scriptsData.map(ob => {
                    if (ob.status === 'complete') {
                        ob.status = this.scriptStatus[0];
                    }
                    if (ob.isSelected) {
                        ob.isSelected = false;
                    }
                    return ob;
                });
            }, 30000);
            this.updateDataRequest.emit(true);
            this.selectedScripts = [];
            // }
        });
    }

    onSelectReport(evtObj) {
        evtObj.isSelected = !evtObj.isSelected;
        this.selectedReports.push(evtObj);
        this.selectedReports = this.selectedReports.filter(v => v.isSelected);
    }

    onDownload() {
        this.selectedReports.map(value => {
            this.singleFileDownload(value);
        });
    }

    previewReport(itemsResult) {
        this.selectedReportNames = [];
        if (this.selectedReports.length > 0) {
            this.selectedReports.map((value, index) => {
                const obj = {};
                obj['reportName'] = value['reportName'];
                obj['serialNo'] = value['serialNo'];
                obj['timestamp'] = value['timestamp'];
                obj['displayName'] = value['displayName'];
                obj['deviceId'] = value['deviceId'];
                this.selectedReportNames[index] = obj;
            });
        } else {
            if (itemsResult) {
                const obj = {};
                obj['reportName'] = itemsResult['reportName'];
                obj['serialNo'] = itemsResult['serialNo'];
                obj['timestamp'] = itemsResult['timestamp'];
                obj['displayName'] = itemsResult['displayName'];
                obj['deviceId'] = itemsResult['deviceId'];
                this.selectedReportNames[0] = obj;
            }
        }

        if (this.selectedReportNames.length > 0) {
            this.openScriptDialog();
        }
    }

    openPullDialog() {
        const dialogRef = this.dialog.open(TaskDialogComponent, {
            width: '65%',
            hasBackdrop: true,
            data: {
                title: Languages.get('global.transferFrom', 'capitalizeEach'),
                actionBtnTitle: Languages.get('global.transfer', 'capitalize'),
                type: 'Pull'
            }
        });

        dialogRef.afterClosed().subscribe(path => {
            if (path) {
                this.scriptService
                    .pullTask(path, this.deviceId, this.serialNo)
                    .subscribe(res => {
                        this.updateDataRequest.emit(true);
                    });
            }
        });
    }

    openPushDialog() {
        const dialogRef = this.dialog.open(TaskDialogComponent, {
            width: '65%',
            hasBackdrop: true,
            data: {
                title: Languages.get('global.transferTo', 'capitalizeEach'),
                actionBtnTitle: Languages.get('global.transfer', 'capitalize'),
                type: 'push'
            }
        });

        this.pushDialogSub = dialogRef.afterClosed().subscribe(dialogData => {
            if (dialogData) {
                // make call for getting key.
                this.sasTokenSub = this.scriptService
                    .getSasToken(
                        this.deviceId,
                        dialogData.file.name,
                        dialogData.file.size
                    )
                    .subscribe(sasToken => {
                        // generate uuid for backend
                        let uuid = UUID.UUID();

                        // send back end status for in progress.
                        const inProgressStatusObject = this.constructStatusObject(
                            sasToken,
                            dialogData,
                            'Inprogress',
                            uuid
                        );
                        this.fileUploadSub = this.fileUtilsService
                            .sendFileUploadStatus(inProgressStatusObject)
                            .subscribe(res => res);

                        // completion data for when progress completes to be emitted from component.
                        const completionData = {
                            completeUrl: sasToken['completeUrl'],
                            uuid: uuid,
                            path: dialogData.path,
                            fileName: dialogData.file.name
                        };

                        // construct access token for file upload.
                        const accessToken: ISasToken = {
                            container: sasToken.containerName,
                            filename: sasToken.blobName,
                            storageAccessToken: `?${sasToken.sasToken}`,
                            storageUri: sasToken.blobUrl
                        };
                        // start file upload.
                        this.fileTransferStates.push(
                            this.fileUtilsService.uploadFile(
                                dialogData.file,
                                accessToken,
                                uuid,
                                completionData
                            )
                        );
                        // for maintaining status in deactivation guard.
                        this.fileTransferData.push(completionData);
                    });
            }
        });
    }

    onTransferCompleted(ev: any) {
        this.successUploadSub = this.fileUtilsService
            .confirmFileSuccessUpload(
                this.serialNo,
                ev.completionData.completeUrl,
                ev.completionData.uuid,
                `${ev.completionData.path}\\${ev.completionData.fileName}`,
                this.deviceId,
            )
            .subscribe(response => { });
        this.fileTransferStates.splice(ev.index, 1);
        this.fileTransferData.splice(ev.index, 1);
        this.cdr.markForCheck();
    }

    onUploadFailed(ev) {
        const sasToken = {
            completeUrl: ev.completeUrl
        };

        const failedStatusObject = this.constructStatusObject(
            sasToken,
            {
                path: ev.path,
                file: {
                    name: ev.fileName
                }
            },
            'Failed',
            ev.uuid
        );
        this.fileUploadSub = this.fileUtilsService
            .sendFileUploadStatus(failedStatusObject)
            .subscribe(res => res);
    }

    constructStatusObject(sasToken, fileData, status, uuid) {
        return {
            uuid: uuid,
            event: 'TaskFilePush',
            eventType: 'Task',
            deviceId: this.deviceId,
            status: status,
            description: 'Transfer file, ' + fileData.file.name,
            properties: {
                filename: fileData.file.name,
                destinationPath: `${fileData.path}\\${fileData.file.name}`,
                bloburl: sasToken['completeUrl']
            }
        };
    }

    openScriptDialog(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '450px',
            disableClose: true,
            hasBackdrop: true,
            id: 'document-preview-dialog'
        });
        dialogRef.componentInstance.isSuccess = false;
        dialogRef.componentInstance.showLogo = false;
        dialogRef.componentInstance.isPreviewReport = true;
        if (this.selectedReportNames && this.selectedReportNames.length > 0) {
            dialogRef.componentInstance.previewReport = this.selectedReportNames;
        }
    }

    onRunReport() {
        this.selectedReports.map(ob => {
            ob.status = this.scriptStatus[1];
            return ob;
        });

        this.reportsData.map(ob => {
            this.selectedReports.map(v => {
                if (ob.id === v.id) {
                    ob.status = v.status;
                }
            });
        });

        this.reportsData.forEach(command => {
            if (command.status === 'running') {
                this.commandList.push(command.agentFunctionName);
            }
        });

        this.scriptService.runCommand(this.selectedReports).subscribe(res => {
            // if (res.status === 200) {
            this.reportsData.map(ob => {
                if (ob.status === 'running') {
                    ob.status = this.scriptStatus[2];
                }
                return ob;
            });
            this.commandList = [];
            setTimeout(() => {
                this.reportsData.map(ob => {
                    if (ob.status === 'complete') {
                        ob.status = this.scriptStatus[0];
                    }
                    if (ob.isSelected) {
                        ob.isSelected = false;
                    }
                    return ob;
                });
            }, 30000);
            this.updateDataRequest.emit(true);
            this.selectedReports = [];
            // }
        });
    }

    /**
     * method: called when new tab is clicked on
     * @param index number of the tab that got clicked
     */
    onSelectedTabChange(index: number) {
        this.updateSelectedTab.emit(index);
    }

    singleFileDownload(evtObj) {
        this.scriptService
            .getPreviewReport(evtObj.deviceId, evtObj.reportName)
            .subscribe(res => {
                const fileName = `${evtObj.reportName}.txt`;
                this.download(fileName, res);
            });
    }

    onAddToSelectedTasks(item, checked) {
        if (checked) {
            this.selectedTasks.push(item);
        } else {
            this.selectedTasks.splice(
                this.selectedTasks.findIndex(task => task === item),
                1
            );
        }
    }

    download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
        );
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    createNote() {
        this.noteCreationStatus = !this.noteCreationStatus;
        this.saveStatus = true;
    }

    cancelCreateNote() {
        if (!this.saveStatus) {
            this.noteCreationStatus = !this.noteCreationStatus;
            this.isViewNote = true;
        } else {
            this.noteCreationStatus = !this.noteCreationStatus;
            this.saveStatus = false;
        }
    }

    validateInput() {
        const inputText = <HTMLInputElement>(
            document.getElementById('input-text12')
        );
        const inputTextArea = <HTMLInputElement>(
            document.getElementById('input-textarea12')
        );
        if (
            inputText.value.trim().length > 0 &&
            inputTextArea.value.trim().length > 0
        ) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }

    saveNote() {
        const inputText = <HTMLInputElement>(
            document.getElementById('input-text12')
        );
        const inputTextArea = <HTMLInputElement>(
            document.getElementById('input-textarea12')
        );
        if (
            inputText.value.trim().length > 0 &&
            inputTextArea.value.trim().length > 0 &&
            inputText.value.length > 0 &&
            inputTextArea.value.length > 0
        ) {
            if (this.saveStatus) {
                this.newNote['message'] = inputTextArea.value;
                this.newNote['title'] = inputText.value;
                this.newNote['firstName'] = this.userInfo['firstName'];
                this.newNote['lastName'] = this.userInfo['lastName'];
                this.newNote['ownerMailId'] = this.userInfo['emailId'];
                this.newNote['jobTitle'] = this.userInfo['jobTitle'];
                this.newNote['deviceId'] = this.deviceId;
                this.newNote['serialNo'] = this.serialNo;
                this.saveStatus = false;
                this.scriptService.createNote(this.newNote).subscribe(res => {
                    res.timeStamp = moment(res.timeStamp)
                        .local()
                        .format(AppConfiguration.global.fullDateAndTimeFormat);
                    this.getNotesList();
                    this.noteCreationStatus = !this.noteCreationStatus;
                    this.saveStatus = false;
                    this.isValid = false;
                    this.openSnackBar('Successfully Created New Note');
                });
            } else {
                const editDetail = {};
                editDetail['noteId'] = this.specificNotesData.id;
                editDetail['message'] = inputTextArea.value;
                editDetail['title'] = inputText.value;
                this.scriptService.saveNote(editDetail).subscribe(data => {
                    data.timeStamp = moment(data.timeStamp)
                        .local()
                        .format(AppConfiguration.global.fullDateAndTimeFormat);
                    this.specificNotesData = data;
                    this.openSnackBar('Successfully Edited');
                    this.cancelCreateNote();
                    this.isViewNote = true;
                    this.isValid = false;

                    if (this.specificNotesData.comments) {
                        this.specificNotesData.comments.map((value, index) => {
                            this.specificNotesData.comments[
                                index
                            ].timeStamp = moment(
                                this.specificNotesData.comments[index].timeStamp
                            )
                                .local()
                                .format(
                                    AppConfiguration.global
                                        .fullDateAndTimeFormat
                                );
                        });
                    }
                });
            }
        }
    }

    viewNote(itemDetails) {
        this.isViewNote = !this.isViewNote;
        this.getSpecificNoteData(itemDetails);
    }

    editNote() {
        this.isViewNote = !this.isViewNote;
        this.noteCreationStatus = true;
        setTimeout(() => {
            const ele = <HTMLInputElement>(
                document.getElementById('input-textarea12')
            );
            ele.value = this.specificNotesData.message;
            const ele1 = document.getElementById('input-text12');
            ele1.setAttribute('value', this.specificNotesData.title);
        }, 100);
    }

    deleteNote() {
        this.openConfirmDialog();
    }

    openConfirmDialog(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px'
        });
        dialogRef.componentInstance.confirmTitle = Languages.get(
            'global.confirmDeleteNoteTitle',
            'capitalize'
        );
        dialogRef.componentInstance.isCloseDialog = false;
        dialogRef.componentInstance.isDeleteConfirm = true;
        dialogRef.componentInstance.isSuccess = false;
        dialogRef.componentInstance.showLogo = false;
        dialogRef.componentInstance.success.subscribe(() => {
            this.scriptService
                .deleteNote(this.specificNotesData.id)
                .subscribe(data => {
                    this.openSnackBar(data['revnotes']);
                    this.backNote();
                    this.getNotesList();
                });
        });
    }

    openSnackBar(status) {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px'
        });
        dialogRef.componentInstance.messageContent = status;
        dialogRef.componentInstance.isCloseDialog = true;
        dialogRef.componentInstance.isDeleteConfirm = false;
        dialogRef.componentInstance.isSuccess = true;
        dialogRef.componentInstance.showLogo = true;
    }

    getNotesList() {
        this.scriptService.getNotesList(this.deviceId).subscribe(res => {
            this.notesData = res;
            if (this.notesData) {
                this.notesData.map(value => {
                    if (value.timeStamp) {
                        value.timeStamp = moment(value.timeStamp)
                            .local()
                            .format(
                                AppConfiguration.global.fullDateAndTimeFormat
                            );
                    }
                });
            }
        });
    }
    getSpecificNoteData(itemDetails) {
        this.specificNotesData = null;
        this.loading = false;
        this.scriptService
            .getSpecificNotesData(itemDetails.id, this.deviceId)
            .subscribe(res => {
                this.specificNotesData = res;
                this.loading = true;

                if (
                    this.specificNotesData.ownerMailId === this.userInfo.emailId
                ) {
                    this.isEditDelete = true;
                } else {
                    this.isEditDelete = false;
                }

                if (this.specificNotesData.timeStamp) {
                    this.specificNotesData.timeStamp = moment(
                        this.specificNotesData.timeStamp
                    )
                        .local()
                        .format(AppConfiguration.global.fullDateAndTimeFormat);
                }
                if (this.specificNotesData.comments) {
                    this.specificNotesData.comments.map((value, index) => {
                        this.specificNotesData.comments[
                            index
                        ].timeStamp = moment(
                            this.specificNotesData.comments[index].timeStamp
                        )
                            .local()
                            .format(
                                AppConfiguration.global.fullDateAndTimeFormat
                            );
                    });
                }
            });
    }

    backNote() {
        this.isViewNote = !this.isViewNote;
    }

    newCommentOfNote() {
        const inputText = <HTMLInputElement>(
            document.getElementById('input-newcomment')
        );
        if (inputText.value.trim().length > 0) {
            const newComment: INewComment = {
                ownerMailId: this.userInfo.emailId,
                jobTitle: this.userInfo.jobTitle,
                firstName: this.userInfo.displayName,
                lastName: null,
                comment: inputText.value
            };
            this.scriptService
                .createNewCommentNote(newComment, this.specificNotesData.id)
                .subscribe(data => {
                    data.timeStamp = moment(data.timeStamp)
                        .local()
                        .format(AppConfiguration.global.fullDateAndTimeFormat);
                    data.comments.map((value, index) => {
                        data.comments[index].timeStamp = moment(
                            data.comments[index].timeStamp
                        )
                            .local()
                            .format(
                                AppConfiguration.global.fullDateAndTimeFormat
                            );
                    });
                    this.specificNotesData = data;
                    inputText.value = '';
                });
        }
    }

    formSingleScriptPayload() {
        const singleScript = this.selectedScripts[0];
        this.selectedScripts = {
            'deviceIdAndMap': {},
            'commandAction': singleScript
        };
        this.revolutionList.forEach(revolution => {
            this.selectedScripts.deviceIdAndMap[revolution.deviceId] = revolution.serialNo;
        });
    }
}
