import { Pipe, PipeTransform } from '@angular/core';
import { AppConfiguration } from 'reach/reach.configuration';

@Pipe({
  name: 'operatorTypePipe'
})
export class OperatorTypePipe implements PipeTransform {

  public conditions = AppConfiguration.operators['deploymentType'];
  transform(value: any, args?: any): any {
    if (value !== false && value !== true) {
      this.conditions.forEach(condition => {
        if (value === condition.value) {
          value = condition.displayName;
        }
      });
      return value;
    } else if (value === true) {
      value = this.conditions[5].displayName;
      return value;
    } else {
      value = this.conditions[0].displayName;
      return value;
    }
  }

}
