import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppConfiguration } from 'reach/reach.configuration';

@Injectable()
export class MomentConfig {

    constructor() {
        moment.locale(AppConfiguration.global.locale);
    }
}
