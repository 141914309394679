import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AdalGuard, AdalService } from 'adal-angular4';
import { AuthService } from 'reach/common/auth-module';

@Injectable()
export class AuthGuard extends AdalGuard {

  constructor(adalSvc: AdalService, private router: Router, private authService: AuthService) {
    super(adalSvc);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl: state.url
        }
      });
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
