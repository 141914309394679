import { Injectable } from '@angular/core';
import { CommonService } from '../../shared-module/services/common.service';
import { Observable } from 'rxjs';
import { ICaseDetailsHistory } from '@reach/reach.interface';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { AppConfiguration } from '@reach/reach.configuration';

@Injectable()
export class CaseHistoryService {

    constructor(private commonService: CommonService) { }

    getCaseHistoryService(id?, startDate?, endDate?): Observable<ICaseDetailsHistory> {
        const serviceUrl = `${CommonService.serverUrl}/casedata/unit-life-data`
            + `?machineId=${id}&startDate=${startDate}&endDate=${endDate}`;
        return this.commonService.get<ICaseDetailsHistory>(serviceUrl).pipe(
            catchError(err => this.commonService.handleError(err)));
    }

    getMetaDataService(serialNo, startDate, endDate): Observable<any> {
        const serviceUrl = `${CommonService.serverUrl}/unit-life-data/unit/metaData?serialNo=${serialNo}&startDate=${startDate}&endDate=${endDate}`;
        return this.commonService.get<any>(serviceUrl).pipe(
            catchError(err => this.commonService.handleError(err)));
    }

    getOdometerDetailsService(serialNo): Observable<any> {
        const serviceUrl = `${CommonService.serverUrl}/unit-life-data/unit/getOdometer?serialNo=${serialNo}`;
        return this.commonService.get<any>(serviceUrl).pipe(
            catchError(err => this.commonService.handleError(err)));
    }

    getOnSiteTimeSpent(startDate, endDate, serialNumber): Observable<any> {
        const serviceUrl = `${CommonService.serverUrl}/casesbyonsitehours/date-range/unitlife/?startDate=${endDate}`
            + `&endDate=${startDate}&serialNo=${serialNumber}&chartdata=false`;
        return this.commonService.get<any>(serviceUrl);
    }

    getAverageHourSpent(startDate, endDate, serialNumber) {
        const serviceUrl = `${CommonService.serverUrl}/averagehoursspentonsite/date-range/unitlife/`
            + `?startDate=${endDate}&endDate=${startDate}&serialNo=${serialNumber}&chartdata=false`;
        return this.commonService.get<any>(serviceUrl);
    }

    getSlaData(startDate, endDate, serialNumber, selectedZone) {
        const serviceUrl = `${CommonService.serverUrl}/servicelevelagreement/date-range/unitlife/`
            + `?startDate=${endDate}&endDate=${startDate}&serialNo=${serialNumber}&chartData=false&zone=${selectedZone}`;
        return this.commonService.get<any>(serviceUrl);
    }

    getFirstTimeFix(startDate, endDate, serialNumber) {
        const serviceUrl = `${CommonService.serverUrl}/first-time-fix/date-range/unitlife/`
            + `?startDate=${endDate}&endDate=${startDate}&serialNo=${serialNumber}&chartdata=false`;
        return this.commonService.get<any>(serviceUrl);
    }

    getNumberOfCasesByHour(startDate, endDate, serialNumber) {
        const serviceUrl = `${CommonService.serverUrl}/casesbyhour/date-range/allweekdays/unitlife/`
            + `?startDate=${endDate}&endDate=${startDate}&serialNo=${serialNumber}&chartdata=false`;
        return this.commonService.get<any>(serviceUrl);
    }

    getCasesByArea(startDate, endDate, serialNumber, selectedArea) {
        const serviceUrl = `${CommonService.serverUrl}/casesbyarea/date-range/unitlife/`
            + `?startDate=${endDate}&endDate=${startDate}&serialNo=${serialNumber}&chartdata=false&area=${selectedArea}`;
        return this.commonService.get<any>(serviceUrl);
    }

    getFrequencyDispatch(startDate, endDate, serialNumber) {
        const serviceUrl = `${CommonService.serverUrl}/highfrequencydispatchstores/date-range/unitlife/`
            + `?startDate=${endDate}&endDate=${startDate}&serialNo=${serialNumber}&chartdata=false`;
        return this.commonService.get<any>(serviceUrl);
    }
}
