import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of, throwError } from 'rxjs';
import { AppConfiguration, Languages } from 'reach/reach.configuration';
import { catchError, flatMap } from 'rxjs/operators';

@Injectable()
@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    transform(url): Observable<SafeUrl> {
        return this.http
            .get(url, {responseType: 'blob'}).pipe(
                flatMap(val => {
                    if (val.type === 'application/json') {
                        return AppConfiguration.global.isOldBrowser ? Observable.create(obs => {
                            const reader = new FileReader();
                            reader.onload = () => obs.next(this.sanitizer.bypassSecurityTrustUrl((reader.result)));
                            reader.onloadend = () => obs.complete();
                            reader.readAsDataURL(val);
                        }) : of(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)));
                    } else {
                        return throwError(Languages.get('global.notAnImage', 'capitalize'));
                    }
                }
                ),
                catchError(() => {
                    const noImage = document.createElement('canvas');
                    const noImageRef = noImage.getContext('2d');
                    noImage.width = 200;
                    noImage.height = 200;
                    noImageRef.lineWidth = 4;
                    noImageRef.strokeStyle = '#0067A555';
                    noImageRef.beginPath();
                    noImageRef.strokeRect(2, 2, 196, 196);
                    noImageRef.moveTo(0, 0);
                    noImageRef.lineTo(200, 200);
                    noImageRef.moveTo(200, 0);
                    noImageRef.lineTo(0, 200);
                    noImageRef.stroke();
                    return of(this.sanitizer.bypassSecurityTrustUrl(noImage.toDataURL('image/png')));
                }));
    }
}
