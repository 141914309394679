import { Pipe, PipeTransform } from '@angular/core';
import { AppConfiguration } from 'reach/reach.configuration';
import * as moment from 'moment';
@Pipe({
  name: 'searchFilterPipe'
})
export class SearchFilterPipe implements PipeTransform {

  AppConfiguration = AppConfiguration;

  transform(value: any, searchName: string): any {
    if (!value) {
      return [];
    }
    if (!searchName) {
      return value;
    }
    searchName = searchName.toLowerCase();
    return value.filter(v => {
      searchName = searchName.toLowerCase();
      let lastUpdated;
      let serialNo;
    if (v.serial) {
      serialNo = v.serial;
      v.serialNo = serialNo;
    }
      if (v.packageHistory) {
        lastUpdated = v.packageHistory[v.packageHistory.length - 1].lastUpdated;
        lastUpdated = (moment(Date.parse(lastUpdated)).format(AppConfiguration.global.fullDateAndTimeFormat));
      } else {
        lastUpdated = '';
      }
      if (v.commandName) {
        // for search script
        const res = v.commandName.toLowerCase().includes(searchName);
        return res;
      } else if (v.packageName && v.userName && v.deploymentDate && v.versionNumber) {
        const packageNameRes = v.packageName.toLowerCase().includes(searchName);
        const userName = v.userName.toLowerCase().includes(searchName);
        const versionNumber = v.versionNumber.toLowerCase().includes(searchName);
        const deploymentDateRes = v.deploymentDate.toLowerCase().includes(searchName);
        if (packageNameRes) {
          return packageNameRes;
        } else if (userName) {
          return userName;
        } else if (versionNumber) {
          return versionNumber;
        } else if (deploymentDateRes) {
          return deploymentDateRes;
        }

      } else if (v.packageName || v.versionNumber || v.createdBy || lastUpdated) {
        // for search package
        let packageCreatedByRes;
        let packageNameRes;
        let packageVersionRes;
        let packageCreateDate;
        let lastUpdatedDate;
         packageNameRes = v.packageName.toLowerCase().includes(searchName);
         packageVersionRes = v.versionNumber.toLowerCase().includes(searchName);
        if (v.createdBy) {
        packageCreatedByRes = v.createdBy.toLowerCase().includes(searchName);
        }
         packageCreateDate = (moment(v.lastUpdatedTime).format(AppConfiguration.global.fullDateAndTimeFormat)).includes(searchName);
         lastUpdatedDate = lastUpdated.includes(searchName);

        if (packageNameRes) {
          return packageNameRes;
        } else if (packageVersionRes) {
          return packageVersionRes;
        } else if (packageCreatedByRes) {
          return packageCreatedByRes;
        } else if (packageCreateDate) {
          return packageCreateDate;
        } else if (lastUpdatedDate) {
          return lastUpdatedDate;
        }

      } else if (v.serialNo || v.model) {
        // for search revolution in Deploy package
        const baNumRes = v.serialNo.toLowerCase().includes(searchName);
        const baModelRes = v.model.toLowerCase().includes(searchName);
        if (baNumRes) {
          return baNumRes;
        } else {
          return baModelRes;
        }
      } else if (v.displayName) {
        const employeeNameRes = v.displayName.toLowerCase().includes(searchName);
        return employeeNameRes;

      } else if (v.mail) {
        const customerNameRes = v.mail.toLowerCase().includes(searchName);
        if (customerNameRes) {
          return customerNameRes;
        }
      } else if (typeof v === 'string' || Object.prototype.toString.call(v) === '[object Object]' && v.name) {
        const list = v.name.toLowerCase().includes(searchName);
        return list;
      }
    });
  }
}
