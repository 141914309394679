import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { Languages } from 'reach/reach.configuration';
import { Location } from '@angular/common';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
    @Input() navigateTo?: string;
    @Input() param?: string;

    @Input() isNavigate = false;
    Languages = Languages;

    constructor(private location: Location, public router: Router) { }

    onBackButton() {
        if (!this.isNavigate) {
            this.location.back();
        } else {
            if (this.param) {
                const key = this.param.split('?');
                const queryParam = key[1].split('=');
                this.router.navigate([`${this.navigateTo}`], {queryParams: { [queryParam[0]] : queryParam[1]}});
            } else {
                this.router.navigate([`${this.navigateTo}`]);
            }
        }
    }
}
