import { Pipe, PipeTransform } from '@angular/core';

/**
 * LocationTrimPipe
 * @desc removes location abreviation that is dislayed in CommonReportsList
 */
@Pipe({
    name: 'locationTrim'
})
export class LocationTrimPipe implements PipeTransform {
    transform(value: any, trimType?: any): any {
        // regex /(\s[A-Z]{1,3})-([0-9]+)/g:
        // \s white space followed by
        // [A-Z]{1,3} any 1-3 capital leters followed by
        // -([0-9]+) a dash and any amount of numbers

        if (trimType === 'activity') {
            let arr = value.split(',');
            return `${arr[0]},`;
        } else {
            return value ? value.replace((/(\s[A-Z]{1,3})-([0-9]+)/g), '') : 'NA';
        }
    }
}
