import { NgModule } from '@angular/core';
import { DialogComponent } from './dialog.component';
import { DialogUtilService } from './dialog-util.service';
import { ScriptsTabModule } from '../scripts-tab-module/scripts-tab.module';
import { SharedModule } from '../shared-module/shared.module';


@NgModule({
    declarations: [
        DialogComponent
    ],
    providers: [
        DialogUtilService
    ],
    imports: [
        SharedModule,
        ScriptsTabModule,
    ],
    entryComponents: [
        DialogComponent
    ]
})
export class DialogModule { }



