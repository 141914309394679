import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserPermission } from '../user-permission.enum';
import { LocalStorageService, SessionStorageService, PreviewModeService, ClientService } from '@reach/common/shared-module';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService implements OnDestroy {
    permissionsMap$: BehaviorSubject<any> = new BehaviorSubject(null);
    refreshTimer = 1000 * 60 * 30;
    refreshPermissionSub: Subscription;
    userPermissionSub: Subscription;
    permissionsObject: any;

    // check if we can conditionally inject services in angular
    constructor(
        public clientService: ClientService,
        private localStorage: LocalStorageService,
        private previewService: PreviewModeService,
        private sessionStorage: SessionStorageService
    ) {

    }

    ngOnDestroy() {
        if (this.refreshPermissionSub) {
            this.refreshPermissionSub.unsubscribe();
        }

        if (this.userPermissionSub) {
            this.userPermissionSub.unsubscribe();
        }
    }

    /**
     * Gets all permissions from back end
     * @author Lakshit Rawat
     */
    getPermissionsFromBackEnd() {
        let permissionsMap = {};
        this.clientService.globalPermissions$
            .pipe(
                map(globalPermissions => this.fillDefaultPermissions(globalPermissions)),
                switchMap(permissionsMapRet => {
                    permissionsMap = permissionsMapRet;
                    return this.clientService.clientDetails$;
                }))
            .subscribe(userInfo => {
                if (userInfo && userInfo['permissions']) {
                    userInfo['permissions'].forEach(
                        permissionId => (permissionsMap[permissionId] = true)
                    );
                    userInfo['permissionsMap'] = permissionsMap;
                    // store user info for session storage in preview mode only. otherwise in local storage.
                    if (this.previewService.isPreviewMode()) {
                        this.sessionStorage.setItem('userInfo', userInfo);
                    } else {
                        this.localStorage.setItem('userInfo', userInfo);
                    }
                    // subscribed at directive to get permissions.
                    this.permissionsMap$.next(permissionsMap);
                }
            });
    }

    fillDefaultPermissions(globalPermissions) {
        let permMap = {};
        for (let permissionId in globalPermissions) {
            if (globalPermissions.hasOwnProperty(permissionId)) {
                permMap[permissionId] = false;
            }
        }
        return permMap;
    }

    isPermitted(key: string) {
        if (key === UserPermission.alwaysShow) {
            return true;
        }
        // if preview mode return permission list from session storage else from local storage.
        if (!this.previewService.isPreviewMode()) {
            return this.localStorage.getItem('userInfo')['permissionsMap'][key];
        } else {
            return this.sessionStorage.getItem('userInfo')['permissionsMap'][key];
        }
    }
}
