import { Component, OnInit } from '@angular/core';
import { OverlayService } from './overlay.service';

@Component({
  selector: 'app-fullscreen-overlay',
  templateUrl: './fullscreen-overlay.component.html',
  styleUrls: ['./fullscreen-overlay.component.scss']
})
export class FullscreenOverlayComponent implements OnInit {

  constructor(private overlayService: OverlayService) { }

  ngOnInit() {
  }

  onClose(ev: MouseEvent) {
    this.overlayService.overlayClicked.next(ev);
  }

}
