import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeHeading'
})
export class CapitalizeHeadingPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value.includes('Sla')) {
      const str = value.substring(0, 4).toUpperCase() + value.substring(4);
      return str;
    } else {
      return value;
    }
  }

}
