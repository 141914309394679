import { Component, OnInit } from '@angular/core';
import { AuthService } from './common/auth-module/auth.service';
import { AppConfiguration } from './reach.configuration';

/**
 * @ignore
 */
@Component({
  selector: 'app-root',
  templateUrl: './reach.component.html',
  styleUrls: ['./reach.component.scss']
})

export class ReachComponent implements OnInit {

  loading = true;
  constructor(private authService: AuthService) {
    // this.authService.initFlow(AppConfiguration.env.adalConfig);
  }

  ngOnInit(): void {
    this.authService.handleWindowCallback();
  }
}
