import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPermissionDirective } from './directives/user-permission.directive';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UserPermissionsRoutingModule } from './user-permissions-routing.module';

@NgModule({
  imports: [
    CommonModule,
    UserPermissionsRoutingModule

  ],
  declarations: [
    UserPermissionDirective,
    UnauthorizedComponent,
  ],
  exports: [UserPermissionDirective]
})
export class UserPermissionsModule { }
