import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@reach/common/login-module/login.component';
import { AuthGuard } from '@reach/common/shared-module';
import { LoginGuard } from '@reach/common/login-module/login.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: './common/main-module/main.module#MainModule',
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'iot',
  //   loadChildren: '../home-module/home.module#HomeModule'
  // },
  {
    path: 'na',
    redirectTo: '/home/exec-dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard]
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled'})
  ],
  exports: [
    RouterModule
  ]
})
export class ReachRoutingModule {
}
