import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { ReachModule } from '@reach/reach.module';

const noop = function (): void {};

if (environment.production) {
    enableProdMode();
    console.log = noop;
}

platformBrowserDynamic().bootstrapModule(ReachModule);
