import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatProgressBar } from '@angular/material';
import { Observable } from 'rxjs';
import { IUploadProgress } from 'reach/common/shared-module';
import { Languages } from 'reach/reach.configuration';

@Component({
  selector: 'app-file-progress-item',
  templateUrl: './file-progress-item.component.html',
  styleUrls: ['./file-progress-item.component.scss']
})
export class FileProgressItemComponent implements OnInit, OnChanges {

  progressCompletion$: Observable<any>;
  @Input() indexInArray = 0;
  @Input() progressItem: IUploadProgress;
  @Input() showBar = true;

  @Output() progressCompletion: EventEmitter<any> = new EventEmitter();
  @Output() uploadFailed: EventEmitter<any> = new EventEmitter();

  @ViewChild('progressBar') progressBar: MatProgressBar;
  public Languages = Languages;

  constructor() { }

  ngOnInit() {
    if (this.progressItem) {
      this.progressItem.progress = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progressItem && changes.progressItem.currentValue && changes.progressItem.currentValue.progress) {
      this.progressItem.progress = changes.progressItem.currentValue.progress;
      if (changes.progressItem.currentValue.progress === 100) {
        this.progressCompletion.emit({index: this.indexInArray, completionData: this.progressItem.completionData});
      }
      if (changes.progressItem.currentValue.progress.error) {
        this.uploadFailed.emit(this.progressItem.completionData);
      }
    }
  }
}
