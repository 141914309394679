// import { CaseHistoryComponent } from './common/common-reports/case-history-module/case-history.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieModule } from 'ngx-cookie';
// import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from 'reach/common/auth-module';
import { AuthGuard } from 'reach/common/shared-module/guards/auth.guard';
import {
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule
} from '@angular/material';
import {
    HttpTokenInterceptor,
    DraggableBoxComponent,
    FullscreenOverlayComponent,
    GlobalAutocompleteComponent,
    // WindowRefService
} from '@reach/common/shared-module';
import { UserPermissionGuard } from '@reach/common/user-permissions-module';
import { SharedModule } from './common/shared-module/shared.module';
import { AdalService } from 'adal-angular4';
import { ReachComponent } from '../reach/reach.component';
import { ReachRoutingModule } from './reach-routing.module';
import { LoginModule } from './common/login-module/login.module';
import {SessionStorageService} from '@reach/common/shared-module';
import { LoginComponent } from './common/login-module/login.component';
import { DialogModule } from './common/dialog-module/dialog.module';
import { DialogComponent } from './common/dialog-module';
import { CaseHistoryService } from './common/common-reports/case-history-module/case-history.service';
// import { DashboardService } from '../reach/common/common-reports/dashboard-module/dashboard.service';

@NgModule({
    declarations: [
        ReachComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReachRoutingModule,
        CookieModule.forRoot(),
        // OAuthModule.forRoot(),
        SharedModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        LoginModule,
        MatDialogModule,
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        DialogModule
    ],
    entryComponents: [
        DraggableBoxComponent,
        FullscreenOverlayComponent,
        GlobalAutocompleteComponent,
        DialogComponent
    ],
    providers: [
        // WindowRefService,
        AuthGuard,
        AdalService,
        SessionStorageService,
        AuthService,
        CaseHistoryService,
        // DashboardService,
        UserPermissionGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [ReachComponent]
})
export class ReachModule {
}
