import { Injectable } from '@angular/core';
  import { JwtHelperService } from '@auth0/angular-jwt';
  import { AppConfiguration } from 'reach/reach.configuration';
  import { AdalService } from 'adal-angular4';
  import { SessionStorageService, LocalStorageService } from 'reach/common/shared-module/services/utilities/storage.service';
 
  // const PROFILE_URL = 'users/profile';
  @Injectable()
  export class AuthService {
     static readonly authServerUrl = AppConfiguration.authUrl;
     jwtHelper = new JwtHelperService();
 
     constructor(
       private adalService: AdalService,
       private sessionStorage: SessionStorageService,
       private localStorageService: LocalStorageService
     ) {}
 
     get isAuthenticated(): boolean {
       return true;
       // return this.adalService.userInfo.authenticated;
     }
 
     public initFlow(configOptions) {
       // this.adalService.init(configOptions);
     }
 
     logOut() {
       this.sessionStorage.clear();
       // this.adalService.logOut();
       this.localStorageService.clear();
     }
 
     handleWindowCallback() {
       // this.adalService.handleWindowCallback();
     }
 
     get userInfo() {
       // return this.adalService.userInfo;
       return {};
     }
 
     login() {
       // this.adalService.logOut();
       // this.adalService.login();
     }
  }
