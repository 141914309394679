import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BehaviorSubject } from 'rxjs';

interface PromptConfirmData {
  placeholder?: string;
  inputType?: string;
  header?: string;
  buttonText?: string;
}
@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit {

  inputData: PromptConfirmData;
  formValue = '';
  submission: BehaviorSubject<string> = new BehaviorSubject(null);
  errorState: BehaviorSubject<{ showError: boolean, errorMsg: string }> = new BehaviorSubject(null);
  @Input() showLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromptConfirmData
  ) {
    this.inputData = data;
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submission.next(this.formValue);
    this.showLoading = false;
  }

}
