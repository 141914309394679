import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Languages } from 'reach/reach.configuration';

@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload: ElementRef;

  public Languages = Languages;

  public invalidFileName = false;

  private file: any;

  private fileDraggedOver = false;

  private fileName: string = null;

  private path: string = null;


  constructor(
    public dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  actionEnabled(): boolean {
    if (this.data.type === 'push') {
      return this.path && this.file && !this.invalidFileName;
    } else {
      return (this.path ? true : false);
    }
  }

  close() {
    this.dialogRef.close();
  }

  onActionClick() {
    if (this.data.type === 'push') {
      this.dialogRef.close({
        path: this.path,
        file: this.file
      });
    } else {
      this.dialogRef.close(this.path);
    }
  }

  handleFileDragout(e) {
    e.preventDefault();
    this.fileDraggedOver = false;
  }

  handleFileDragover(e) {
    e.preventDefault();
    this.fileDraggedOver = true;
  }

  handleFileDrop(e) {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      this.fileDraggedOver = false;
      this.file = e.dataTransfer.files[0];
      this.fileName = this.file.name;
      // this.validateFileName(this.fileName);
    }
  }

  handlePathChange(e) {
    this.path = e.target.value;
  }

  onClickFileUpload() {
    this.fileUpload.nativeElement.onchange = (event) => {
      event.stopPropagation();
      if (event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.fileName = this.file.name;
        // this.validateFileName(this.fileName);
      }
    };
    this.fileUpload.nativeElement.click();
  }

  validateFileName(fileName: string) {
    if (fileName.indexOf(' ') >= 0) {
      this.invalidFileName = true;
    } else {
      this.invalidFileName = false;
    }
  }

}
