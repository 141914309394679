import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISasToken } from '../azure-storage/azureStorage';
import { BlobStorageService } from '../azure-storage/blob-storage.service';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../common.service';
import { AppConfiguration, Languages } from '@reach/reach.configuration';
import { MatDialog } from '@angular/material';

export interface IUploadProgress {
    filename: string;
    progress: any;
    uuid: string;
    completionData: any;
}

@Injectable({
    providedIn: 'root'
})
export class FileUtilsService {

    updateStatusUri = `${AppConfiguration.iotUrl}/configxml/initiate/event`;
    successFileUploadUrl = `${AppConfiguration.iotUrl}/agentcommandinvocation/configxml`;

    constructor(
        public blobStorage: BlobStorageService,
        private dialog: MatDialog,
        private commonService: CommonService) {

    }

    /**
     * Downloads text file
     * @author Lakshit Rawat
     * @param type: 'blob-content' or 'link'. use from AppConfiguration.downloadType
     * @param filenameOrLink: file name in case of blob. link in case of link.
     * @param [fileContentText]
     */
    downloadTextFile(type: string, filenameOrLink: string, fileContentText = null, fileName = '') {
        const element = document.createElement('a');
        // for text content, convert text to blob content and set to href for initiating download.
        if (type === AppConfiguration.downloadType.blob) {
            element.href = window.URL.createObjectURL(new Blob([fileContentText], { type: 'text/plain' }));
            element.setAttribute('download', filenameOrLink);
            // for download from url, set url as href and set download attribute.
        } else if (type === AppConfiguration.downloadType.link) {
            element.href = filenameOrLink;
            element.setAttribute('target', '_blank');
            element.setAttribute('download', fileName);
        }
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    /**
     * Uploads file.
     * @author Lakshit Rawat
     * @param file: uploaded file
     * @param sasToken: from be
     * @param uuid: uuid generated by fe.
     * @param completionData: data to be sent to the bend after progress is completed.
     * @returns file upload progress.
     */
    uploadFile(file, accessToken: ISasToken, uuid = '', completionData?): Observable<IUploadProgress> {
        return this.blobStorage
            .uploadToBlobStorage(accessToken, file)
            .pipe(map(progress => this.mapProgress(file, progress, uuid, completionData),
                catchError(err => {
                    return err;
                })));
    }

    /**
     * Sends files upload status
     * @author Lakshit Rawat
     * @param statusObject: status of the upload.
     * @returns: status of the post call.
     */
    sendFileUploadStatus(statusObject) {
        return this.commonService.postResponseWithHeader(this.updateStatusUri, statusObject, { responseType: 'text' }).pipe(
            map(res => res),
            catchError(err => {
                return err;
            })
        );
    }

    /**
     * Confirms file success upload
     * @author Lakshit Rawat
     * @param serialNo: serial no of the machine.
     * @param bloburl: blob url of the file.
     * @param groupId: uuid of the file upload
     * @param destinationPath: destination path in the agent machine
     * @returns: success of the post call.
     */
    confirmFileSuccessUpload(serialNo, bloburl, groupId, destinationPath, deviceId) {
        const successBody = {
            'function': 'TaskFilePush',
            'serialNo': serialNo,
            'blobUrl': bloburl,
            'groupId': groupId,
            'destinationPath': destinationPath,
            'deviceId': deviceId
        };

        return this.commonService.postResponseWithHeader(this.successFileUploadUrl, successBody, { responseType: 'text' }).pipe(
            map(res => res),
            catchError(err => {
                return err;
            })
        );
    }

    exportCSVFile(headers, fileTitle, items) {
        if (headers) {
            items.unshift(headers);
        }
        // Convert Object to JSON
        const jsonObject = JSON.stringify(items);
        const csv = this.convertToCSV(jsonObject);
        const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            // tslint:disable-next-line: forin
            for (const index in array[i]) {
                if (line !== '') {
                    line += ',';
                }

                line += array[i][index];
            }

            str += line + '\r\n';
        }
        return str;
    }
    /**
     * Maps progress and completion data for showing progress.
     * @author Lakshit Rawat
     * @param file: to be uploaded
     * @param progress: current upload progress of the file.
     * @param uuid: uuid generated bu be
     * @param completionData: data to be sent to the bend after progress is completed.
     * @returns progress object for the component.
     */
    private mapProgress(file: File, progress: number, uuid?: string, completionData?): IUploadProgress {
        return {
            filename: file.name,
            progress: progress,
            uuid: uuid,
            completionData: completionData
        };
    }

}
