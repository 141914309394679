import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppConfiguration, Languages } from 'reach/reach.configuration';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    @Output() timeout = new EventEmitter<boolean>(false);

    isTimeout = false;

    Languages = Languages;

    constructor() {}

    ngOnInit() {
        setTimeout(() => {
            this.isTimeout = true;
            this.timeout.emit(true);
        }, AppConfiguration.global.api.timeOut * 1000);
    }
}
