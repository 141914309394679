import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { ICustomerPermission, ICustStoreDetails, IUserCount, IUserInfo, IUserPermission } from '@reach/reach.interface';
import { ClientService } from './client.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    constructor(private commonService: CommonService, private clientService: ClientService) { }

    // getUserInfoService(): Observable<IUserInfo> {
    //     const serviceUrl = `${CommonService.serverUrl}/user-server/aad/user/profile`;
    //     return this.commonService.get(serviceUrl).pipe(map((res: IUserInfo) => {
    //         // res.userType = 'super-admin';
    //         return res;
    //     }));
    //     // .catch(err => this.commonService.handleError(err));
    // }

    getUserPermissionService(userType): Observable<any> {
        return this.clientService.clientDetails$.pipe(
            map((response) => {
                return response;
            }));
        // .catch(err => this.commonService.handleError(err));
    }

    getSelectedUserInfoService(id): Observable<IUserInfo> {
        const serviceUrl = `${CommonService.serverUrl}/user-server/users/getuser/${id}`;
        return this.commonService.get<IUserInfo>(serviceUrl).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

    getUserCountService(): Observable<IUserCount> {
        const serviceUrl = `${CommonService.serverUrl}/user-server/admin/getuserscount`;
        return this.commonService.get<IUserCount>(serviceUrl).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

    getSelectedUserPermissionService(id): Observable<IUserPermission> {
        return this.clientService.getAdminPermissionById(id).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

    getSelectedCustomerPermissionService(id): Observable<ICustomerPermission> {
        return this.clientService.getCustomerAdminPermissionsById(id).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

    updatePermissionService(id, data, anotherUser) {
        const serviceUrl = anotherUser === false ? `${CommonService.serverUrl}`
            + `/user-server/admin/updatepermission` : `${CommonService.serverUrl}`
            + `/user-server/admin/updatepermissions/${id}`;
        return this.commonService.put(serviceUrl, data).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

    updateCustomerPermissionService(id, data, anotherUser) {
        const serviceUrl = `${CommonService.serverUrl}`
            + `/user-server/admin/updatepermissions/${id}`;
        return this.commonService.put(serviceUrl, data).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

    getStoreDetails(): Observable<ICustStoreDetails> {
        const serviceUrl = `${CommonService.serverUrl}/user-server/customeradmin/getstoredetails`;
        return this.commonService.get<ICustStoreDetails>(serviceUrl).pipe(
            map(response => response));
        // .catch(err => this.commonService.handleError(err));
    }

}
