import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Languages } from 'reach/reach.configuration';
import { EventEmitter } from '@angular/core';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-file-download-checkbox',
  templateUrl: './file-download-checkbox.component.html',
  styleUrls: ['./file-download-checkbox.component.scss']
})
export class FileDownloadCheckboxComponent implements OnInit {

  @Input() isDownloadable = true; // Whether the file item should show dobwnload option
  @Input() isPreviewable: boolean; // whether clicking a file should show the preview dialog box.
  @Input() fileName: string; // name of the file.
  @Input() timestamp: string; // timestamp of the file, if any.
  @Input() checkboxValue: boolean;  // defulat value of the checkbox, before selection.
  @Input() disableDownloadBtn: boolean; // disabling single downloadd button for multiple downloads.
  @Input() status: string; // status of the file downloaded or uploaded. Till now only 'transferred' implemented.
  @Input() showStatus: boolean; // whether to show status or not.
  @Input() disableCheckbox: boolean; // disabling checkbox.

  @Output() checkboxValueChanged = new EventEmitter();
  @Output() previewClicked = new EventEmitter();
  @Output() downloadClicked = new EventEmitter();

  @ViewChild('checkbox') matCheckbox: MatCheckbox;

  public Languages = Languages;

  constructor() { }

  ngOnInit() {
  }

  onHeadingClicked() {
    if (this.isPreviewable) {
      // used for opening the file preview dialogs in checkboxes.
      this.previewClicked.emit();
    } else {
      if (!this.disableCheckbox) {
        // ghost clicking the heading area to emulate the checkbox chenging event.
        this.matCheckbox.checked = !this.matCheckbox.checked;
        this.checkboxValueChanged.emit({
          checked: this.matCheckbox.checked,
          source: this.matCheckbox
        });
      }
    }
  }

  onCheckBoxChanged(ev) {
    this.checkboxValueChanged.emit(ev);
  }

  onDownloadFile() {
    this.downloadClicked.emit();
  }

}
