import { ClientService } from '../client.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IFilter, ISelectedFIlter } from 'reach/reach.interface';
import { AppConfiguration } from 'reach/reach.configuration';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    public selectedFilter = new BehaviorSubject<IFilter>(AppConfiguration.global.filter.defaultFiler);
    public selectedFilterOption = new BehaviorSubject<any>({ value: '', filterType: '' });
    public clearAll = new BehaviorSubject<boolean>(false);
    public accessCheck = new BehaviorSubject<boolean>(false);

    private selectedFilterValuesForApiCalls = AppConfiguration.global.filter.defaultFiler;

    constructor(public clientService: ClientService) {
    }

    getSelectedFilter() {
        return this.selectedFilter.asObservable();
    }

    /**
     * Returns actual values of filters instead of observables so that other services can
     * easily access them without subscriptions
     */
    getSelectedFilterValuesForApiCalls() {
        this.getSelectedFilter().subscribe(updatedFilter => {
            const customerList = [];
            this.clientService.getCustomerListForLoggedInUser()
                .subscribe(res => {
                    if (res) {
                        res.forEach(element => {
                            customerList.push(element.customerName);
                        });
                        this.selectedFilterValuesForApiCalls = {
                            customer: updatedFilter.customer[0] === 'ALL'
                                ? customerList.slice()
                                : updatedFilter.customer,
                            model: updatedFilter.model[0] === 'ALL' ? [] : updatedFilter.model,
                            period: updatedFilter.period,
                            location: updatedFilter.location[0] === 'ALL' ? [] : updatedFilter.location,
                            dateRange: this.getDateRange(updatedFilter)
                        };
                    }
                });
        });
        return this.selectedFilterValuesForApiCalls;
    }

    getSelectedOption() {
        return this.selectedFilterOption.asObservable();
    }

    publishFilterData(customer, model, period?: string, location?, dateRange?): void {
        this.selectedFilter.next({ customer, model, period, location, dateRange });
        this.getSelectedFilterValuesForApiCalls();
    }

    publishSelectedOption(value, filterType): void {
        this.selectedFilterOption.next({ value: value, filterType: filterType });
    }

    triggerClearAll(value): void {
        this.clearAll.next(value);
    }

    getDateRange(updatedFilter) {
        return (!updatedFilter.dateRange.start && !updatedFilter.dateRange.end)
        ? { start: null, end: null }
        : { start: updatedFilter.dateRange.start, end: updatedFilter.dateRange.end };
    }

    filterTypeTwoInterceptor(selectedFilter: any): any {
        if (selectedFilter[0][0] === 'ALL' || selectedFilter[0] === 'ALL'
            || selectedFilter.length === 0
            || selectedFilter[0][0][0] === 'ALL'
        ) {
            return [];
        } else if (Array.isArray(selectedFilter[0][0])) {
            return selectedFilter[0][0];
        } else if (Array.isArray(selectedFilter[0])) {
            return selectedFilter[0];
        } else {
            return selectedFilter;
        }
    }
}
