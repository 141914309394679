import { Injectable } from '@angular/core';
import { AppConfiguration } from '@reach/reach.configuration';
// added for cisrcular dependency
import { CommonService } from '@reach/common/shared-module/services/common.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PackageDeploymentNotifcationsService {

  // public previewUrl = `${AppConfiguration.iotUrl}/packages/`;
  // public ApprovalListUrl = `${AppConfiguration.iotUrl}/packages/details?`;
  // public confirmPackageUrl = `${this.previewUrl}schedule`;
  constructor(
    public commonService: CommonService
  ) { }

  // getRevolutionsReport(ids) {
  //   return this.commonService.get(`${this.ApprovalListUrl}` + `packageId=${ids.packageId}` + `&trackingId=${ids.trackingId}`).pipe(
  //     map((data) => {
  //       return data;
  //     }),
  //     catchError(err => this.commonService.handleError(err)));
  // }

  // approveRequest(payload) {
  //   return this.commonService.post(this.confirmPackageUrl, payload).pipe(
  //     map(response => {
  //       return response;
  //     }),
  //     catchError(err => this.commonService.handleError(err)));
  // }
}
