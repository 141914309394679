import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Languages } from '@reach/reach.configuration';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-virtual-scroll-table',
  templateUrl: './virtual-scroll-table.component.html',
  styleUrls: ['./virtual-scroll-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed, void => expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class VirtualScrollTableComponent implements OnInit, OnChanges {
  @Output() pageChange = new EventEmitter<any>();
  @Output() tableRowInfo = new EventEmitter<any>();
  @Output() selectionData = new EventEmitter<any>();
  
  @Input() rows;
  @Input() columnConfig;
  @Input() pageLength;
  @Input() pageSize;
  @Input() hidePageSize;
  @Input() rowAction = "action";
  @Input() actionItems: any;
  @Input() toggleExpansion = "expansionToggle";
  @Input() select = "select";
  @Input() isLoading: boolean;

  // Optional Table fields
  @Input() isExpandable = false;
  @Input() isSelectable = false;
  @Input() isPaginated = false;
  @Input() isActionRow = false;
  @Input() isFilterable = false;
  @Input() selectedAgentDisable = false;
  @Input() tooltipText;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(VirtualScrollerComponent)
  @ViewChild('headerCheckbox') headerCheckbox: MatCheckbox;
  
  public noResults$ = new Subject<boolean>();
  public displayColumns: string[] = [];
  public Languages = Languages;
  public virtualScroller: VirtualScrollerComponent;
  public loading$: boolean;
  public mockExpansionText: string = 'this is the expanded section';
  public isFiltered: boolean;
  private paginator: MatPaginator;
  private sort: MatSort;
  public isDisableCheckBox = false;
  
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  constructor() { }

  ngOnInit() {
    if (this.displayColumns) {
      this.columnConfig.forEach(element => {
        this.displayColumns.push(element.key);
      });
    }
    if (this.rowAction && this.isActionRow) {
      this.displayColumns = [...this.displayColumns, this.rowAction];
    }
    if (this.isExpandable) {
      this.displayColumns = [this.toggleExpansion, ...this.displayColumns];
    }
    if (this.isSelectable) {
      this.displayColumns = [this.select, ...this.displayColumns];
    }
  }

  setDataSourceAttributes() {
    if (this.rows && this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      this.dataSource.sort = this.sort;
    }
    if (this.rows && this.isPaginated && this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnChanges() {
    if (this.rows) {
      this.dataSource = new MatTableDataSource(this.rows.reverse());
      this.sort.disableClear = true;
    }

    // sort
    var columnConfiguration = this.columnConfig;
    if (this.dataSource && this.dataSource.filterPredicate) {
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        let b = [];
        columnConfiguration.map(value => {
          if (value.isFilterable) {
            b.push(data[value.key].toString().toLowerCase().includes(filter))
          }
        })
        let str = "";
        for (let i = 0; i < b.length; i++) {
          i === b.length - 1 ? str += "b[" + i.toString() + "]" : str += "b[" + i.toString() + "] || ";
        }
        return eval(str);
      }
    }
    if (this.dataSource && this.dataSource.data && !this.dataSource.data.length) {
      this.sort.disabled = true;
      this.isDisableCheckBox = !this.isDisableCheckBox;
    }
  }

  /**
   * @method applyFilter
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    this.isFiltered = true;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (!(this.dataSource && this.dataSource.filter && this.dataSource.filter.length)) {
      this.isFiltered = !this.isFiltered;
    }

    this.noResults$.next(
      this.dataSource.filteredData.length === 0
    );
    if (!(this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) && this.isSelectable) {
      this.headerCheckbox.disabled = true;
    } else if (this.isSelectable) {
      this.headerCheckbox.disabled = false;
    }
  }

  /**
   * @method onPageChange
   * @param event 
   */
  onPageChange(event) {
    this.pageChange.emit(event)
  }

  /**
   * Gets the row information of table
   * @param event 
   */
  rowInfo(event) {
    this.tableRowInfo.emit(event);
  }

  /**
   * Checks for all rows checked/selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (!this.selectedAgentDisable) {
      const numRows = this.dataSource.data.length;
      const pageRows = this.dataSource.filteredData.length;
      return this.isFiltered ? numSelected === pageRows : numSelected === numRows;
    } else {
      if (this.dataSource && this.dataSource.data) {
        const numRowsMinusExcluded = this.dataSource.data
          .filter(row => !row.isDisable)
          .length;
        return numSelected === numRowsMinusExcluded;
      }
    }
  }

  /**
   *Emits header level Checkbox selection 
   */
  masterToggle() {
      if (this.isAllSelected()) {
        this.selection.clear();
      } else if (this.isFiltered) {
        this.dataSource.connect().value.forEach(row => this.selection.select(row));
      } else if (!this.selectedAgentDisable) {
        this.dataSource.data.forEach(row => this.selection.select(row));
      } else if (this.dataSource && this.dataSource.data){
        this.dataSource.data.forEach(row => {
          if (!row.isDisable) {
            this.selection.select(row);
          }
        });
      }
      this.selectionData.emit(this.selection.selected);
  }

  /**
   * Emits data when table row data checkbox is toggled
   */
  toggle() {
    this.selectionData.emit(this.selection.selected);
  }
}
