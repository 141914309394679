import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import {
  BLOB_STORAGE_TOKEN,
  IBlobService,
  IBlobStorage,
  ISasToken,
  ISpeedSummary
} from './azureStorage';

@Injectable()
export class BlobStorageService {

  public fileUplaodError: BehaviorSubject<any> = new BehaviorSubject(null);
  public cancelUploadFilter = {
    cancel: false,              // Set to true to cancel an in-progress upload

    loggingOn: true,            // Set to true to see info on console

    onCancelComplete: null,     // Set to a function you want called when a cancelled request is (probably?) complete,
    // i.e. when returnCounter==nextCounter. Because when you cancel an upload it can be many
    // seconds before the in-progress chunks complete.

    // Internal from here down

    nextCounter: 0,             // Increments each time next() is called. a.k.a. 'SentChunks' ?
    returnCounter: 0,           // Increments each time next()'s callback function is called. a.k.a. 'ReceivedChunks'?
    handle: function (requestOptions, next) {
      let self = this;
      if (self.cancel) {
        self.log('cancelling before chunk sent');
        return;
      }
      if (next) {
        self.nextCounter++;
        next(requestOptions, function (returnObject, finalCallback, nextPostCallback) {
          self.returnCounter++;
          if (self.cancel) {
            self.log('cancelling after chunk received');
            if (self.nextCounter === self.returnCounter && self.onCancelComplete) {
              self.onCancelComplete();
            }

            // REALLY ??? Is this the right way to stop the upload?
            return;

          }
          if (nextPostCallback) {
            nextPostCallback(returnObject);
          } else if (finalCallback) {
            finalCallback(returnObject);
          }
        });
      }
    },
    log: function (msg) {
      if (this.loggingOn) {
      }
    },
  };

  constructor(@Inject(BLOB_STORAGE_TOKEN) private blobStorage: IBlobStorage) { }



  /**
   * Uploads to blob storage.
   * @author Lakshit Rawat
   * @param sasToken
   * @param file
   * @returns blob storage
   */
  uploadToBlobStorage(sasToken: ISasToken, file: File): Observable<number> {
    const customBlockSize = this.getBlockSize(file);
    const options = { blockSize: customBlockSize };
    const blobService = this.createBlobService(sasToken.storageAccessToken, sasToken.storageUri);

    blobService.singleBlobPutThresholdInBytes = customBlockSize;

    return this.uploadFile(blobService, sasToken, file, options);
  }


  /**
   * Creates blob service from plugin factory with exponential retries.
   * @author Lakshit Rawat
   * @param accessToken: sas token from be.
   * @param blobUri: bloburl generated from be.
   * @returns blob service.
   */
  private createBlobService(accessToken: string, blobUri: string): IBlobService {
    return this.blobStorage
      .createBlobServiceWithSas(blobUri, accessToken)
      .withFilter(this.cancelUploadFilter)
      .withFilter(new this.blobStorage.ExponentialRetryPolicyFilter());
  }


  /**
   * Uploads file
   * @author Lakshit Rawat
   * @param blobService: blob service in azure plugin.
   * @param accessToken: access token provided by the be.
   * @param file: file to be uploaded.
   * @param options: options for upload. here blockSize is provided
   * @returns observable for tracking progress.
   */
  private uploadFile(
    blobService: IBlobService,
    accessToken: ISasToken,
    file: File,
    options: { blockSize: number }
  ): Observable<number> {
    return new Observable<number>(observer => {
      const speedSummary = blobService.createBlockBlobFromBrowserFile(
        accessToken.container,
        accessToken.filename,
        file,
        options,
        error => this.callback(error, observer, file)
      );
      speedSummary.on('progress', () => this.getProgress(speedSummary, observer));
    }).pipe(
      startWith(0),
      distinctUntilChanged(),
    );
  }


  /**
   * Gets progress and updates the progress according to that.
   * @author Lakshit Rawat
   * @param speedSummary
   * @param observer
   */
  private getProgress(speedSummary: ISpeedSummary, observer: Subscriber<any>): void {
    const progress = parseInt(speedSummary.getCompletePercent(2), 10);
    // observer.next({'error': 'error'});
    observer.next(progress === 100 ? 99 : progress);
  }


  /**
   * Callbacks blob storage service for error or completion.
   * @author Lakshit Rawat
   * @param error
   * @param observer: progress observer.
   * @param file: file uploaded.
   */
  private callback(error: any, observer: Subscriber<any>, file): void {
    if (error) {
      // observer.error(error);
      observer.next({ error: error });
      this.fileUplaodError.next({ error, file });
    } else {
      observer.next(100);
      observer.complete();
    }
  }



  /**
   * Gets block size of each block depending upon the size of the block.
   * @author Lakshit Rawat
   * @param file
   * @returns block size
   */
  private getBlockSize(file: File): number {
    const size32Mb = 1024 * 1024 * 32;
    const size4Mb = 1024 * 1024 * 4;
    const size512Kb = 1024 * 512;

    return file.size > size32Mb ? size4Mb : size512Kb;
  }
}
