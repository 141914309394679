import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html',
  styleUrls: ['./expansion.component.scss']
})
export class ExpansionComponent implements OnInit {
  @Input() title :TemplateRef<any>
  @Input() expand :TemplateRef<any>
  public panelOpenState;
  
  constructor() { }

  ngOnInit() {
  }

}
