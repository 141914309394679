import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { SharedRoutingModule } from './shared-routing.module';
import { BackButtonComponent } from './back-button/back-button.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingDirective } from './loading/loading.directive';
import { ClientService } from './services/client.service';
import { SecurePipe } from './services/securepipe';

import { HttpTokenInterceptor } from './services/http.interceptor';
import { CommonService } from './services/common.service';
import { HttpLoggerInterceptor } from './services/httpLogger.interceptor';
import { FilterService } from './services/utilities/filter.service';
import { FileUtilsService } from './services/utilities/file-utils.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    MatToolbarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatSortModule,
    MatProgressBarModule
} from '@angular/material';
import { BarChartToolbarComponent } from './bar-chart-toolbar/bar-chart-toolbar.component';
import { MomentConfig } from './services/momentConfig.service';
import { SearchFilterPipe } from './pipes/search-filter-pipe.pipe';
import { AlertStatusPipe } from './pipes/alert-status.pipe';
import { LocationTrimPipe } from './pipes/location-trim.pipe';
import { ListOperationsService } from './services/utilities/list-operations.service';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import {
    RevolutionErrorLogsService
 } from '@reach/iot/revolution-dashboard-module/revolution-dashboard/alert-card-section/revolution-error-logs.service';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { PromptDialogComponent } from './dialogs/prompt-dialog/prompt-dialog.component';
import { BlobStorageService } from './services/azure-storage/blob-storage.service';
import { BLOB_STORAGE_TOKEN, IBlobStorage } from './services/azure-storage/azureStorage';
import { FileDownloadCheckboxComponent } from './components/file-download-checkbox/file-download-checkbox.component';
import { UserPermissionsModule } from '@reach/common/user-permissions-module/user-permissions.module';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { StickyHeaderDirective } from './directives/sticky-header/sticky-header.directive';
import { DraggableBoxComponent } from './components/draggable-box/draggable-box.component';
import { SearchFilterByKeyPipe } from './pipes/search-filter-by-key.pipe';
import { FullscreenOverlayComponent } from './components/fullscreen-overlay/fullscreen-overlay.component';
import { OperatorTypePipe } from './pipes/operator-type.pipe';
import { GlobalAutocompleteComponent } from './components/global-autocomplete/global-autocomplete.component';
import { FileProgressItemComponent } from '../scripts-tab-module/file-progress-item/file-progress-item.component';
import { MaterialModule } from '../material-module';
import { DialogComponent } from '../dialog-module';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CapitalizeHeadingPipe } from './pipes/capitalize-heading.pipe';
import { ExpansionComponent } from './expansion/expansion.component';
import { VirtualScrollTableComponent } from './virtual-scroll-table/virtual-scroll-table.component';

export function azureBlobStorageFactory(): IBlobStorage {
    if (window['AzureStorage']) {
        return window['AzureStorage'].Blob;
    }
}

@NgModule({
    imports: [
        CommonModule,
        SharedRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatChipsModule,
        MatTooltipModule,
        MatMenuModule,
        MatDividerModule,
        MatToolbarModule,
        MatDialogModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatOptionModule,
        MatSortModule,
        MatSelectModule,
        MatFormFieldModule,
        MaterialModule,
        MatProgressBarModule,
        VirtualScrollerModule,
        UserPermissionsModule,
        NgxMatSelectSearchModule,
        MatDatepickerModule
    ],
    declarations: [
        SecurePipe,
        BackButtonComponent,
        LoadingComponent,
        LoadingDirective,
        BarChartToolbarComponent,
        SearchFilterPipe,
        AlertStatusPipe,
        LocationTrimPipe,
        ConfirmDialogComponent,
        PromptDialogComponent,
        FileProgressItemComponent,
        FileDownloadCheckboxComponent,
        TimeFormatPipe,
        StickyHeaderDirective,
        DraggableBoxComponent,
        SearchFilterByKeyPipe,
        FullscreenOverlayComponent,
        OperatorTypePipe,
        GlobalAutocompleteComponent,
        ListFilterComponent,
        CapitalizeHeadingPipe,
        ExpansionComponent,
        VirtualScrollTableComponent
    ],
    exports: [
        AlertStatusPipe,
        BackButtonComponent,
        ConfirmDialogComponent,
        PromptDialogComponent,
        LoadingComponent,
        FileProgressItemComponent,
        LoadingDirective,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        BarChartToolbarComponent,
        LocationTrimPipe,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatChipsModule,
        MatTooltipModule,
        MatMenuModule,
        MatDividerModule,
        MatToolbarModule,
        MatDialogModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SecurePipe,
        SearchFilterPipe,
        TimeFormatPipe,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        VirtualScrollerModule,
        FileDownloadCheckboxComponent,
        UserPermissionsModule,
        SearchFilterByKeyPipe,
        OperatorTypePipe,
        FullscreenOverlayComponent,
        ListFilterComponent,
        CapitalizeHeadingPipe,
        ExpansionComponent,
        VirtualScrollTableComponent
    ],
    entryComponents: [
        ConfirmDialogComponent,
        PromptDialogComponent,
        LoadingComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                AlertStatusPipe,
                CapitalizeHeadingPipe,
                LocationTrimPipe,
                SecurePipe,
                SearchFilterPipe,
                TimeFormatPipe,
                ClientService,
                CommonService,
                FilterService,
                MomentConfig,
                FileUtilsService,
                ListOperationsService,
                RevolutionErrorLogsService,
                SearchFilterByKeyPipe,
                OperatorTypePipe,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpLoggerInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpTokenInterceptor,
                    multi: true
                },
                // added for azure blob file upload.
                BlobStorageService,
                {
                    provide: BLOB_STORAGE_TOKEN,
                    useFactory: azureBlobStorageFactory,
                }
            ]
        };
    }
}


