import { Injectable } from '@angular/core';
import { AppConfiguration } from '@reach/reach.configuration';
import { Observable } from 'rxjs';
import { ITraceFilesDirectory} from '@reach/reach.interface';
// circular dependency.
import { CommonService } from '@reach/common/shared-module/services/common.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RevolutionErrorLogsService {


  constructor(
    private commonService: CommonService
  ) { }

  getErrorLogs(deviceId: string): Observable<ITraceFilesDirectory> {
    return this.commonService.get<any>(`${AppConfiguration.iotUrl}/task/trace/id?deviceId=${deviceId}`).pipe(
      map(response => {
        let res = (response && response.length) ? response[0] : {};

        let updateFiles = (dir) => {
          if (dir.files) {
            dir.files = dir.files.map((file) => {
              return {
                name: file,
                selected: false
              };
            });
          }
          if (dir.directories && dir.directories.length) {
            dir.directories.forEach(directory =>  updateFiles(directory));
            return dir;
          } else {
            return dir;
          }
        };

        if (res.directoryStructure) {
          updateFiles(res.directoryStructure);
        }

        return res;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  getErrorLogFile(traceId: string, filePath: string): Observable<string> {
    return this.commonService.get<any>(
      `${AppConfiguration.iotUrl}/task/trace/download/${traceId}?filePath=${filePath}`,
      { responseType: 'text' })
      .pipe(
        map(res => res ),
        catchError(err => this.commonService.handleError(err))
      );
  }

  getUpdatedErrorLogs(deviceId, serialNo) {
    return this.commonService.get<any>(
      `${AppConfiguration.iotUrl}/agentcommandinvocation/revlog/id?deviceId=${deviceId}&serialNo=${serialNo}`)
      .pipe(
        map(res => res ),
        catchError(err => this.commonService.handleError(err))
      );
  }

}
