import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class HttpLoggerInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const started = Date.now();
        return next
            .handle(req).pipe(
                tap(event => {
                    // const elapsed = Date.now() - started;
                    if (event instanceof HttpResponse) {
                        /*
                                + `Status code: ${event.status} ${event.statusText}`);
                        */
                    }
                }
                ));
    }
}
