import { Component, EventEmitter, Inject, Input, Output, OnInit } from '@angular/core';
import { Languages, AppConfiguration } from 'reach/reach.configuration';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'reach/common/auth-module';
import {
  ScriptsTabService
} from '@reach/common/scripts-tab-module/scripts-tab.service';
// import for circular dependency.
import { FileUtilsService } from '@reach/common/shared-module/services/utilities/file-utils.service';
import {
  RevolutionErrorLogsService
} from 'reach/iot/revolution-dashboard-module/revolution-dashboard/alert-card-section/revolution-error-logs.service';
import { ISingleLog } from '@reach/reach.interface';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {
  PackageDeploymentNotifcationsService
} from '@reach/iot/packages-module/package-deployment-notifcations/package-deployment-notifcations.service';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() messageTitle: string;
  @Input() confirmTitle: string;
  @Input() messageContent: string;
  @Input() isSuccess = true;
  @Input() isConfirm = false;
  @Input() isDeleteConfirm = false;
  @Input() isLoginRedirect = false;
  @Input() isLogout = false;
  @Input() isCloseDialog = false;
  @Input() showLogo = true;
  @Input() userName: string;
  @Input() userEmail: string;
  @Input() isScript = false;
  @Input() isDisconnect = false;
  @Input() isSingleSelectionCheckbox = false;
  @Input() revolutionList = [];
  @Input() previewReport = [];
  @Input() isPreviewReport = false;
  @Input() isLoading = false;
  @Input() loadingText: string;
  // added for previewing logs @author: Lakshit Rawat
  @Input() previewLogs: Array<ISingleLog>;
  @Input() isPreviewLog: boolean;
  @Input() serialNo: string;

  /* report, system, error */
  @Input() logs: any[];
  @Input() logType: string;
  @Input() isRejected: boolean;
  @Input() approvalLevel: any;
  @Input() apprvalDetails: any;


  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Output() isDirectory: EventEmitter<boolean> = new EventEmitter();

  public currentLogTimestamp: any;
  public previewErrorLog: string;
  public previewLog: string;
  public resultOfPreview;
  public loading = false;
  public currentIndex;
  public commentsText;

  public authUrl = `${AppConfiguration.authUrl}/login`;
  public Languages = Languages;
  public chipLimit = 3;
  public addCommentsMsg: string;

  constructor(
    public authService: AuthService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public scriptService: ScriptsTabService,
    public revolutionErrorLogsService: RevolutionErrorLogsService,
    public fileUtilsService: FileUtilsService,
    public router: Router,
    public packageDepNotificationService: PackageDeploymentNotifcationsService
  ) {
  }

  ngOnInit() {
    this.currentIndex = 0;
    if (this.isPreviewReport) {
      this.scriptService.getPreviewReport(this.previewReport[0].deviceId, this.previewReport[0].reportName).subscribe((res) => {
        this.resultOfPreview = res;
        this.loading = true;
      });
    } else if (this.isPreviewLog) {
      this.setCurrentLogTimeStamp();
      this.scriptService.getSystemLog(this.previewLogs[0], this.serialNo)
        .subscribe(res => {
          this.previewLog = res;
          this.loading = true;
        });
    } else {
      if (this.logType === 'error') {
        // moved here because on init if the above conditions are not satisfied it comes to else and throws error for success.
        let logMetaData = this.logs[0];
        this.revolutionErrorLogsService.getErrorLogFile(logMetaData.traceId, logMetaData.filePath).subscribe(
          (res) => {
            this.previewLog = res;
            this.loading = true;
          }
        );
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.success.emit(true);
  }

  LogOut(): void {
    this.authService.logOut();
  }

  next() {
    if (this.logs.length - 1 > this.currentIndex) {
      let logMetaData = this.logs[++this.currentIndex];
      this.loading = false;
      if (this.logType === 'error') {
        this.revolutionErrorLogsService.getErrorLogFile(logMetaData.traceId, logMetaData.filePath).subscribe(
          (res) => {
            this.previewLog = res;
            this.loading = true;
          }
        );
      }
    }
  }

  previous() {
    if (this.logs.length > 1 && this.currentIndex > 0) {
      let logMetaData = this.logs[--this.currentIndex];
      this.loading = false;
      if (this.logType === 'error') {
        this.revolutionErrorLogsService.getErrorLogFile(logMetaData.traceId, logMetaData.filePath).subscribe(
          (res) => {
            this.previewLog = res;
            this.loading = true;
          }
        );
      }
    }
  }

  previewReportClose() {
    this.dialogRef.close();

  }

  previewReportNext() {
    if (this.previewReport.length - 1 > this.currentIndex) {
      this.currentIndex = this.currentIndex + 1;
      this.loading = false;
      this.scriptService.getPreviewReport
        (
          this.previewReport[this.currentIndex].serialNo,
          this.previewReport[this.currentIndex].reportName
        ).subscribe((res) => {
          this.resultOfPreview = res;
          this.loading = true;
        });
    }
  }

  previewReportPrevious() {
    if (this.previewReport.length > 1 && this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
      this.loading = false;
      this.scriptService.getPreviewReport
        (
          this.previewReport[this.currentIndex].serialNo,
          this.previewReport[this.currentIndex].reportName
        ).subscribe((res) => {
          this.resultOfPreview = res;
          this.loading = true;
        });
    }
  }

  previewReportDownload() {
    const filename = `${this.previewReport[this.currentIndex].reportName}.txt`;
    this.fileUtilsService.downloadTextFile(AppConfiguration.downloadType.blob, filename, this.resultOfPreview);
  }

  downloadLogFile() {
    const filename = `${this.logs[this.currentIndex].fileName}` +
      `${moment(this.logs[this.currentIndex].timestamp).utc().format(AppConfiguration.global.fullDateAndTimeFormat)}`;

    this.fileUtilsService.downloadTextFile(AppConfiguration.downloadType.blob, filename, this.previewLog);
  }

  // next log
  // for system logs
  previewLogNext() {
    if (this.previewLogs.length - 1 > this.currentIndex) {
      this.currentIndex = this.currentIndex + 1;
      this.setCurrentLogTimeStamp();
      this.loading = false;
      this.scriptService.getSystemLog
        (
          this.previewLogs[this.currentIndex],
          this.serialNo
        ).subscribe((res) => {
          this.previewLog = res;
          this.loading = true;
        });
    }
  }

  // previous log
  // for system logs
  previewLogPrevious() {
    if (this.previewLogs.length > 1 && this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
      this.setCurrentLogTimeStamp();
      this.loading = false;
      this.scriptService.getSystemLog
        (
          this.previewLogs[this.currentIndex],
          this.serialNo
        ).subscribe((res) => {
          this.previewLog = res;
          this.loading = true;
        });
    }
  }
  // download log
  previewLogDownload() {
    const filename = `${this.previewLogs[this.currentIndex].file}_` +
      `${moment(this.previewLogs[this.currentIndex].timestamp).local().format(
        AppConfiguration.global.fullDateAndTimeFormat
      )}`;
    this.fileUtilsService.downloadTextFile(AppConfiguration.downloadType.blob, filename, this.previewLog);
  }

  setCurrentLogTimeStamp() {
    this.currentLogTimestamp = this.previewLogs[this.currentIndex].timestamp;
    // `${moment()
    //     .utc().format(AppConfiguration.global.fullDateAndTimeFormat)}`;
  }

  submitComment() {
    const payloadObj = {
      trackingId: '',
      packageId: '',
      approvalLevel: '',
      comments: '',
      deviceIds: ''
    };
    // payloadObj.approvalLevel = this.approvalLevel;

    if (this.data) {
      payloadObj.trackingId = this.data.trackingId;
      payloadObj.packageId = this.data.packageId;
      payloadObj.approvalLevel = this.data.approvalLevel;
      payloadObj.comments = this.commentsText;
      payloadObj.deviceIds = this.data.deviceIds;
    }
    let payload = payloadObj;
    if (payload.comments) {
      payload.comments = payload.comments.split(' ').join('');
    }
    // if (payload.comments) {
    //   this.packageDepNotificationService.approveRequest(payload).subscribe(res => {
    //     if (res) {
    //       this.dialogRef.close();
    //       this.router.navigate(['/home/packages/packages-list-view']);
    //       return res;
    //     }
    //   });
    // } else {
    //   this.addCommentsMsg = Languages.get('global.addCommentsMsg', 'upper');
    // }
  }
}
