import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { Languages } from 'reach/reach.configuration';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { isObject } from 'lodash';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material';
import { IPreviewUserList } from 'reach/reach.interface';
import { PreviewModeService } from '../../services/preview-mode.service';

@Component({
  selector: 'app-global-autocomplete',
  templateUrl: './global-autocomplete.component.html',
  styleUrls: ['./global-autocomplete.component.scss']
})
export class GlobalAutocompleteComponent implements OnInit {

  public Languages = Languages;
  public searchInputControl = new FormControl();
  public filteredItems: Observable<string[]>;
  @ViewChild('autoComplete') autoCompleteRef: MatAutocomplete;
  @Input() items: Array<any>;

  /**
   * key of the object to be searched.
   */
  @Input() searchKey: string;
  @Input() displayKey: string;
  @Output() close: EventEmitter<string> = new EventEmitter();
  @Output() optionSelected: EventEmitter<IPreviewUserList> = new EventEmitter();


  constructor(private previewService: PreviewModeService) { }

  ngOnInit() {
    this.filteredItems = this.searchInputControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value[this.searchKey]),
        map(searchKey => searchKey ? this._filter(searchKey) : this.items.slice())
      );
  }

  selectedDisplayFn(user) {
    return user ? user.mail : undefined;
  }

  onOptionSelected(ev: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit(ev.option.value);
  }


  /**
   * Hosts listener for recording the outside click and closing accordingly.
   * @param ev: mouse click event
   */
  @HostListener('document:click', ['$event'])
  onClick(ev) {
    const clickedEl: HTMLElement = ev.target;
    if (!this.autoCompleteRef.isOpen && !clickedEl.closest('#autoCompleteFormCloseClick')) {
      this.closeAutoComplete();
    }
  }

  closeAutoComplete() {
    this.close.emit('closed');
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (isObject(this.items[0])) {
      return this.items.filter(item => {
        return item[this.searchKey] ? item[this.searchKey].toLowerCase().includes(filterValue) : false;
      });
    } else {
      return this.items.filter(item => {
        return item.toLowerCase().includes(filterValue);
      });
    }
  }
}
