import { Injectable, Inject, ComponentFactoryResolver } from '@angular/core';
import { SessionStorageService } from './utilities/storage.service';
import { Subject, Observable } from 'rxjs';
import { DynamicComponentCreatorService } from './utilities/dynamic-component-creator.service';
import { AppConfiguration } from 'reach/reach.configuration';
import { CommonService } from './common.service';
import { map, catchError } from 'rxjs/operators';
import { IPreviewUserList } from 'reach/reach.interface';

@Injectable({
  providedIn: 'root'
})
export class PreviewModeService {

  factoryResolver: ComponentFactoryResolver;
  activePermissionList: Array<any> = [];
  userPermissionType = '';

  previewClicked$: Subject<boolean> = new Subject();
  private switchingUsersUrl = `${AppConfiguration.env.serviceUrl}/user-server/customeradmin/user-list`;
  private userInformationUrl = `${AppConfiguration.env.serviceUrl}/user-server/aad/user`;

  constructor(private sessionStorage: SessionStorageService,
    @Inject(ComponentFactoryResolver) factoryResolver,
    private compCreatorService: DynamicComponentCreatorService,
    private commonService: CommonService
  ) {
    this.factoryResolver = factoryResolver;
  }

  isPreviewMode() {
    return !!(this.sessionStorage.getItem('userInfo') || window['userInfo']);
  }

  createComponent(component) {
    return this.compCreatorService.createComponentAppRoot(component);
  }

  openPreviewWindow(userInfo) {
    let previewWindow = window.open(`${location.origin}/`);
    previewWindow['userInfo'] = userInfo;
    previewWindow['userInfo']['permissions'] = this.activePermissionList;
    previewWindow['userInfo']['permissionType'] = this.userPermissionType;
    // mail propert is sent as userMailId in app data permissions call.
    previewWindow['userInfo']['userMailId'] = userInfo.mail;
    // not using currently. only for future purposes.
    previewWindow['isPreviewMode'] = true;
  }

  getUsersForSwitching(): Observable<Array<IPreviewUserList>> {
    return this.commonService.get<Array<IPreviewUserList>>
      (this.switchingUsersUrl).pipe(
        map(res => res),
        catchError(err => this.commonService.handleError(err)));
  }

  getUserInformation(email) {
    return this.commonService.get<Array<IPreviewUserList>>
      (`${this.userInformationUrl}?email=${email}`).pipe(
        map(res => res),
        catchError(err => this.commonService.handleError(err)));
  }
}
