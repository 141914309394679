import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilterByKey'
})
export class SearchFilterByKeyPipe implements PipeTransform {

  transform(value: any, searchName?: any, revKey?: any): any {
    if (searchName) {
      searchName = searchName.toLowerCase();
    }
    if (revKey) {
      revKey = revKey.split(',');
    }
    if (!searchName) {
      return value;
    }
    let searchTerm = searchName.split(',');
    if (Array.isArray(searchTerm)) {
      return value.filter(v => {
        return searchTerm.find(val => {
          if (v && v.serial) {
          const serialValue = v.serial.toLowerCase().includes(val.toLowerCase());
          if (serialValue) {
            return v;
          }
        } else if (v && v.serialNo) {
          const serialNoVal = v.serialNo.toLowerCase().includes(val.toLowerCase());
          if (serialNoVal) {
            return v;
          }
        }
        });
      });
    } else {
      return value.filter(v => {
        // revKey.forEach((item) => { // used for filtering with each key.
        if (v[revKey[0]]) {
          const revValue = v[revKey[0]].toLowerCase().includes(searchName);
          if (revValue) {
            return revValue;
          }
        }
        //   });
      });
    }
  }
}
