import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPermissionsService } from '../services/user-permissions.service';
import { UserPermission } from '../user-permission.enum';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionGuard implements CanActivate {

    constructor(private userPermissionService: UserPermissionsService, private router: Router) {

    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userPermissionService.isPermitted(next.data.permissionId) || next.data.permissionId === UserPermission.alwaysShow) {
            return true;
        } else {
            this.router.navigate(['/home']);
            return false;
        }
    }
}
