import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { LoginGuard } from './login.guard';
// import { AuthModule } from '../auth-module/auth.module';
// import { AuthService } from '../auth-module';


@NgModule({
    imports: [],
    declarations: [
        LoginComponent
    ]
})
export class LoginModule {
    constructor() {
    }
 }

