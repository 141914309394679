import { Component, OnInit, ElementRef, AfterViewInit, Renderer2, ViewChild, ComponentRef } from '@angular/core';
import { SessionStorageService } from '../../services/utilities/storage.service';
import { Languages } from 'reach/reach.configuration';
import { PreviewModeService } from '../../services/preview-mode.service';
import { DynamicComponentCreatorService } from '../../services/utilities/dynamic-component-creator.service';
import { GlobalAutocompleteComponent } from '../global-autocomplete/global-autocomplete.component';
import { IPreviewUserList } from 'reach/reach.interface';
import { DialogUtilService } from '@reach/common/dialog-module';

@Component({
  selector: 'app-draggable-box',
  templateUrl: './draggable-box.component.html',
  styleUrls: ['./draggable-box.component.scss']
})
export class DraggableBoxComponent implements OnInit, AfterViewInit {

  public cursorPositions = {
    oldX: 0,
    oldY: 0,
    newX: 0,
    newY: 0
  };
  @ViewChild('draggableDiv') draggableDiv: ElementRef;
  usersForSwitching: Array<IPreviewUserList> = [];
  mouseDownListener: any;
  previewedUser: string;
  public Languages = Languages;
  public switchUserBtnState: 'loading' | 'enabled' = 'loading';
  autoCompleteComp: ComponentRef<GlobalAutocompleteComponent>;


  constructor(
    private sessionStorage: SessionStorageService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private previewService: PreviewModeService,
    private componentCreator: DynamicComponentCreatorService,
    private dialogUtilService: DialogUtilService
  ) { }

  ngOnInit() {
    this.previewedUser = this.sessionStorage.getItem('userInfo')['displayName'];
    // get list of users
    this.previewService.getUsersForSwitching().subscribe(res => {
      this.usersForSwitching = res;
      this.switchUserBtnState = 'enabled';
    });
  }

  ngAfterViewInit() {
    this.makeDraggable(this.draggableDiv);
  }

  makeDraggable(elRef: ElementRef) {
    this.mouseDownListener = this.renderer.listen(elRef.nativeElement, 'mousedown', this.dragOnMouseDown());
  }

  dragOnMouseDown() {
    return (ev: MouseEvent) => {
      ev.preventDefault();
      this.cursorPositions.oldX = ev.clientX;
      this.cursorPositions.oldY = ev.clientY;
      // clearing listeners when mouse is unclicked.
      document.onmouseup = this.removeDragElementEvents();
      // calculate positions and set to the host element.
      document.onmousemove = this.moveElement();
    };
  }

  removeDragElementEvents() {
    return () => {
      document.onmouseup = null;
      document.onmousemove = null;
    };
  }

  moveElement() {
    return (ev: MouseEvent) => {
      this.cursorPositions.newX = this.cursorPositions.oldX - ev.clientX;
      this.cursorPositions.newY = this.cursorPositions.oldY - ev.clientY;
      this.cursorPositions.oldX = ev.clientX;
      this.cursorPositions.oldY = ev.clientY;
      this.renderer.setStyle(this.elRef.nativeElement,
        'left', `${this.elRef.nativeElement.offsetLeft - this.cursorPositions.newX}px`);
      this.renderer.setStyle(this.elRef.nativeElement, 'top',
        `${this.elRef.nativeElement.offsetTop - this.cursorPositions.newY}px`);
    };
  }

  onClose() {
    window.close();
  }


  /**
   * Creates and attaches auto complete component to app root. sets the component inputs for list and key.
   */
  onOpenSwitchUser(ev: MouseEvent) {
    ev.stopPropagation();
    this.autoCompleteComp = <ComponentRef<GlobalAutocompleteComponent>>
      this.componentCreator.createComponentAppRoot(GlobalAutocompleteComponent);
    this.autoCompleteComp.instance.items = this.usersForSwitching;
    this.autoCompleteComp.instance.searchKey = 'mail';
    this.autoCompleteComp.instance.displayKey = 'displayName';
    this.autoCompleteComp.instance.optionSelected.subscribe(user => {
      this.previewService.getUserInformation(user.mail).subscribe(userInfo => {
        if (userInfo['activeCustomerList'].length === 0) {
          this.dialogUtilService.openErrorDialog(Languages.get('errorMessage.noActiveCustomer', 'start'));
        } else {
          this.previewService.activePermissionList = userInfo['permissions'];
          this.previewService.openPreviewWindow(userInfo);
        }
      });
    });
    this.autoCompleteComp.instance.close.subscribe(closed => {
      this.closeSwitchUser();
    });
  }

  closeSwitchUser() {
    if (this.autoCompleteComp) {
      this.componentCreator.removeComponentAppRef(this.autoCompleteComp);
    }
  }
}
