import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setItem(key: string, storageObject: any) {
    localStorage.setItem(key, JSON.stringify(storageObject));
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  clear() {
    localStorage.clear();
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() { }

  setItem(key: string, storageObject: any) {
    sessionStorage.setItem(key, JSON.stringify(storageObject));
  }

  getItem(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  clear() {
    sessionStorage.clear();
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
