import { Observable, throwError, Subject } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfiguration, Languages } from 'reach/reach.configuration';
// import { ErrorMessageDialogComponent } from 'app/role-management-module/error-message/error-message.component';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarRef } from '@angular/material';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
// import { WindowRefService } from './windowRef.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  static readonly serverUrl = AppConfiguration.url;   // changes automatically based on environment
  static readonly httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  static readonly httpOptions = { headers: CommonService.httpHeaders };
  public errorDialogRef: MatDialogRef<any>;
  public errorSnackBarRef: MatSnackBarRef<any>;
  public location: any;


  constructor(
    private http: HttpClient,
    // public window: WindowRefService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.location = window.location;
  }

  checkForErrorDialog() {
    if (this.errorDialogRef) {
      this.errorDialogRef.close();
    }
    if (this.errorSnackBarRef) {
      this.errorSnackBarRef.dismiss();
    }
  }

  get<T>(url: string, options?: {}): Observable<T> {
    this.checkForErrorDialog();
    return this.http.get<T>(url, options || CommonService.httpHeaders)
      .pipe(
        map(data => _.isEmpty(data) ? undefined : data),
        retry(AppConfiguration.global.api.maxRetries)
      );
  }

  // @todo: add interfaces for HttpResponse of each body type in component based on service response
  getResponseWithHeader(url: string, options?: { [header: string]: string | string[] }): Observable<HttpResponse<any>> {
    this.checkForErrorDialog();
    return this.http.get<any>(url, options || { observe: 'response' })
      .pipe(
        // .retry(AppConfiguration.global.api.maxRetries)
        map((data) => {
          return _.isEmpty(data.body) ? undefined : data;
        }));
  }

  post(url: string, body: any, options?: {}): Observable<any> {
    this.checkForErrorDialog();
    return this.http.post(url, body, options || CommonService.httpOptions)
      .pipe(
        map(data => _.isEmpty(data) ? undefined : data)
      );
  }

  postResponseWithHeader(url: string, body: any, options?: { [key: string]: any }): Observable<HttpResponse<any>> {
    this.checkForErrorDialog();
    return this.http.post<any>(url, body, options || { observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === '204' && !response.body) {
            return;
          } else if (_.isEmpty(response.body)) {
            return undefined;
          } else {
            return response;
          }
        })
      );
  }

  put(url: string, body?: any, options?: {}): Observable<any> {
    this.checkForErrorDialog();
    return this.http.put(url, body, options || CommonService.httpOptions)
      .pipe(
        map(data => _.isEmpty(data) ? undefined : data)
      );
  }

  putResponseWithHeader(url: string, body: any, options?: { [header: string]: string | string[] }): Observable<HttpResponse<any>> {
    this.checkForErrorDialog();
    return this.http.put<any>(url, body, options || { observe: 'response' })
      .pipe(map(data => _.isEmpty(data.body) ? undefined : data));
  }

  refresh() {
    this.location.reload();
  }

  delete(url: string, options?: {}): Observable<any> {
    this.checkForErrorDialog();
    return this.http.delete(url, options || CommonService.httpOptions);
  }

  handleError(error: any) {
    const errMsg: string = error.message ? String(error.message) : error.toString();
    this.showErrorMessage(errMsg);
    return throwError(errMsg);
  }

  showErrorMessage(errorMessage: string): void {
    switch (AppConfiguration.global.showServerErrorMessage) {
      case 'dialog':
        this.openDialog(errorMessage);
        break;
      case 'snackbar':
      default:
        this.openSnackBar();
        break;
    }
  }

  openSnackBar(): void {
    this.errorSnackBarRef = this.snackBar.open(
      Languages.get('global.problemLoadingData', 'start'),
      Languages.get('global.tryAgain', 'upper')
    );

    this.errorSnackBarRef.onAction().subscribe(() => {
      this.refresh();
    });
  }

  openDialog(errorMessage: string): void {
    // this.errorDialogRef = this.dialog.open(ErrorMessageDialogComponent, {
    //   maxWidth: '90vw',
    //   id: 'server-error',
    //   data: {
    //     errorMessage: errorMessage,
    //     buttonText: Languages.get('global.tryAgain', 'start')
    //   }
    // });

    this.errorDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refresh();
      }
    });
  }
}

