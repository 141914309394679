import { Injectable } from '@angular/core';
import { DialogComponent } from './dialog.component';
import { MatDialog } from '@angular/material';
import { Languages } from '@reach/reach.configuration';

@Injectable({
  providedIn: 'root'
})
export class DialogUtilService {

  constructor(
    public dialog: MatDialog
  ) { }

  openSuccessDialog(status) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px'
    });
    dialogRef.componentInstance.messageContent = status;
    dialogRef.componentInstance.isCloseDialog = true;
    dialogRef.componentInstance.isDeleteConfirm = false;
    dialogRef.componentInstance.isSuccess = true;
    dialogRef.componentInstance.showLogo = true;
    return dialogRef;
  }

  openErrorDialog(error): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px'
    });
    dialogRef.componentInstance.messageTitle = Languages.get('global.failTitle', 'capitalize');
    dialogRef.componentInstance.messageContent = error;
    dialogRef.componentInstance.isSuccess = false;
    dialogRef.componentInstance.isCloseDialog = true;
  }

  openConfirmDialog(title) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px'
    });
    dialogRef.componentInstance.confirmTitle = title;
    dialogRef.componentInstance.isCloseDialog = false;
    dialogRef.componentInstance.isDeleteConfirm = true;
    dialogRef.componentInstance.isSuccess = false;
    dialogRef.componentInstance.showLogo = false;
    return dialogRef;
  }
}
