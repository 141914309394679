import { Directive, OnInit, OnDestroy, NgZone, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive({
  selector: '[appStickyHeader]'
})
export class StickyHeaderDirective implements OnInit, OnDestroy, AfterViewInit {

  public disableScrollingCheck: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _eventOptions: boolean | { capture?: boolean, passive?: boolean };
  private sticky: number;
  private nativeEl: any;
  private sidenavContent: any;
  private disableScrollingSub: Subscription;
  constructor(private ngZone: NgZone, private elRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this._eventOptions = {
      capture: true,
      passive: true
    };
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll, <any>this._eventOptions);
    });
    this.disableScrollingSub = this.disableScrollingCheck.subscribe(value => {
      if (this.nativeEl) {
        this.removeStickyStyles();
      }
    });
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.nativeEl = this.elRef.nativeElement;
      this.sticky = this.getTopDistance(this.nativeEl);
      this.sidenavContent = document.querySelector('mat-sidenav-content');
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, <any>this._eventOptions);
  }

  scroll = (): void => {
    if (!this.disableScrollingCheck.getValue() && window.innerWidth > 600) {
      this.addStickyStyles();
    } else {
      this.removeStickyStyles();
    }
  }

  addStickyStyles() {
    if (64 > (this.sticky - this.sidenavContent.scrollTop)) {
      this.appendStickyStyles();
    } else {
      this.removeStickyStyles();
    }
  }

  getTopDistance(element) {
    let yPosition = 0;
    while (element) {
      yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
      element = element.offsetParent;
    }

    return yPosition;
  }

  appendStickyStyles() {
    this.renderer.addClass(this.nativeEl, 'sticky-header');
    this.renderer.setStyle(this.nativeEl.nextSibling, 'padding-top', `${this.nativeEl.offsetHeight}px`);
    this.renderer.setStyle(this.nativeEl, 'width', `${this.sidenavContent.clientWidth}px`);
  }

  removeStickyStyles() {
    this.renderer.removeClass(this.nativeEl, 'sticky-header');
    this.renderer.removeStyle(this.nativeEl.nextSibling, 'padding-top');
    this.renderer.removeStyle(this.nativeEl, 'width');
  }

}
