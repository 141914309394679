import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DynamicComponentCreatorService } from '../../services/utilities/dynamic-component-creator.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  public showOverlay: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public overlayClicked: BehaviorSubject<any> = new BehaviorSubject(null);
  public overlayComponent: any;

  constructor(private componentCreator: DynamicComponentCreatorService) {
    this.overlayClicked.subscribe(clicked => {
      if (clicked) {
        this.removeOverlay();
      }
    });
  }

  createOverlay(overlayComponent) {
    this.overlayComponent = this.componentCreator.createComponentAppRoot(overlayComponent);
  }

  removeOverlay() {
    if (this.overlayComponent) {
      this.componentCreator.removeComponentAppRef(this.overlayComponent);
    }
  }

}
