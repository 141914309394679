import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PreviewModeService } from '../services/preview-mode.service';

@Injectable({
  providedIn: 'root'
})
export class PreviewModeGuard implements CanActivate {

  constructor(private previewModeService: PreviewModeService, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.previewModeService.isPreviewMode()) {
        this.router.navigate(['home/exec-dashboard']);
      }
      return !this.previewModeService.isPreviewMode();
  }
}
