import { Component, OnInit, Input, EventEmitter, OnDestroy, SimpleChanges, OnChanges, ViewChild, ElementRef, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, BehaviorSubject, Subscription } from 'rxjs';
import { MatSelectChange, MatOptionSelectionChange, MatSelect, MatOption } from '@angular/material';
import { Languages } from '@reach/reach.configuration';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit, OnDestroy, OnChanges {

  public Languages = Languages;

  // allChecked = false;

  @ViewChild('allChecked') allCheckedElement: MatOption;

  /** placeholder for search key */
  @Input() searchPlaceholder: string;

  /** placeholder for selection key */
  @Input() selectPlaceholder: string;


  /** mapper for data */
  @Input() dataMapper: any;

  /** list to be used for selection width key as display name and value as value to be returned */
  @Input() list: Array<any> = [];

  /** initial selection */
  @Input() defaultSelectedItems: Array<any> = [];

  /**single or multiselection list. By default it is multiple */
  @Input() multiple = true;

  /** emits the selected items on close */
  @Output() selectedItems: EventEmitter<Array<any>> = new EventEmitter();

  /** form control for selected items */
  selectedItemsCtrl: FormControl = new FormControl();

  /** form control for search key */
  searchKeyCtrl: FormControl = new FormControl();

  /** view child for mat select */
  @ViewChild('selectionList') selectionList: MatSelect;

  /** filtered list by search keyword */
  filteredList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);

  /** subscriptions to unsubscribe */
  subscriptions: Array<Subscription> = [];

  /** To check whether the search is enabled or not */
  isSearchEnabled: boolean;

  constructor() { }

  ngOnInit() {
    this.subscriptions.push(
      this.searchKeyCtrl.valueChanges
        .subscribe(() => {
          this.filterList();
        })
    );
    this.allCheckedElement.onSelectionChange.subscribe((ev: MatOptionSelectionChange) => {
      if (ev.isUserInput) {
        if (ev.source.selected) {
          this.selectedItemsCtrl.patchValue(this.list);
        } else {
          this.selectedItemsCtrl.patchValue([]);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['list'] && changes['list'].currentValue && changes['defaultSelectedItems']
      && changes['defaultSelectedItems'].currentValue) {
      this.list = changes['list'].currentValue;
      this.nextingFilteredList();
      this.selectedItemsCtrl.patchValue(this.defaultSelectedItems);
      setTimeout(() => {
        this.selectDeselectAll();
      }, 0);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  filterList() {
    if (!this.list) {
      return;
    }
    let search = this.searchKeyCtrl.value;
    if (!search) {
      this.nextingFilteredList();
      // setTimeout(() => {
      //   this.selectedItemsCtrl.setValue(this.defaultSelectedItems);
      //   this.allCheckedElement.select();
      // }, 0);
      this.isSearchEnabled = false;
      return;
    } else {
      search = search.toLowerCase();
      // this.selectedItemsCtrl.patchValue([]);
      this.isSearchEnabled = true;
    }
    this.filteredList.next(
      this.dataMapper ? this.list.filter(item => this.dataMapper[item].toLowerCase().indexOf(search) > -1)
        : this.list.filter(item => item.toLowerCase().indexOf(search) > -1)
    );
  }

  nextingFilteredList() {
    this.filteredList.next(this.list);
  }

  onOptionChange(ev: MatOptionSelectionChange) {
    if (ev.isUserInput) {
      this.subscriptions.push(
        this.filteredList.pipe(take(1)).subscribe(val => {
          setTimeout(() => {
            if (ev.source.selected) {
              this.selectDeselectAll();
            } else {
              this.allCheckedElement.deselect();
            }
          }, 0);
        })
      );
    }
  }

  onPanelToggled(event) {
    if (!event) {
      this.selectedItemsCtrl.setValue(this.defaultSelectedItems);
    }
    this.selectDeselectAll();
  }

  selectDeselectAll() {
    if (this.allAreSelected()) {
      this.allCheckedElement.select();
    } else {
      this.allCheckedElement.deselect();
    }
  }

  emitSelectedList() {
    this.selectedItems.emit(this.selectedItemsCtrl.value.slice());
    this.defaultSelectedItems = this.selectedItemsCtrl.value.slice();
    this.selectionList.close();
  }

  allAreSelected() {
    return (this.selectedItemsCtrl.value.length === this.list.length
      || this.selectedItemsCtrl.value[0] === 'ALL')
      ;
  }
}
