import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {

  menuNavClose$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchBarClose$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  SearchBarCloseInMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }
}
