import { Injectable, Inject, Injector, EmbeddedViewRef, ApplicationRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentCreatorService {

  factoryResolver: ComponentFactoryResolver;


  constructor(@Inject(ComponentFactoryResolver) factoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef) {
    this.factoryResolver = factoryResolver;
  }


  /**
   * Creates component and attaches it to the app root.
   * @param component: component to be attached.
   * @returns  attached component.
   */
  createComponentAppRoot(component: any) {

    // create the factory for the component.
    const factory = this.factoryResolver.resolveComponentFactory(component);

    // create the component by passing the injector.
    const createdComponent = factory.create(this.injector);

    // attach the view of the app-root for enabling dirty check. append to the DOM.
    this.appRef.attachView(createdComponent.hostView);
    const domElem = (createdComponent.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    return createdComponent;
  }


  /**
   * Removes component attached to app ref
   * @param component: ComponentRef
   */
  removeComponentAppRef(component: ComponentRef<any>) {
    this.appRef.detachView(component.hostView);
    component.destroy();
    const domElem = (component.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.removeChild(domElem);
  }

}
