import {
  Directive, TemplateRef, ViewContainerRef, Input, OnChanges, ElementRef, Renderer2, OnDestroy,
  SimpleChanges, OnInit
} from '@angular/core';
import { UserPermissionsService } from '../services/user-permissions.service';
import { UserPermission } from '../user-permission.enum';
import { Subscription } from 'rxjs';

const DISABLED_CLASS = 'row-disabled';
@Directive({
  selector: '[appUserPermission]'
})
export class UserPermissionDirective implements OnChanges, OnDestroy, OnInit {

  @Input() appUserPermissionUserRole?: number; // Holds user role
  @Input()
  set appUserPermission(permissionId) { // Holds permission of component
    this._permissionId = permissionId;
  }

  @Input() appUserPermissionIsShow = true;
  @Input() appUserPermissionIsDisabled = false;
  public userPermissionMap: any; // Holds permission list
  clickUnRegisterFn: Function;
  permissionMapSub: Subscription;

  private _permissionId: any; // Holds permission id

  constructor(private tref: TemplateRef<any>, public vcr: ViewContainerRef, public userPersmissionsService: UserPermissionsService,
    private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.permissionMapSub = this.userPersmissionsService.permissionsMap$.subscribe(permMap => {
      if (permMap) {
        this.userPermissionMap = permMap;
        this.handleComponentView(permMap);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.userPermissionMap) {
      this.handleComponentView(this.userPermissionMap);
    }
  }

  ngOnDestroy() {
    if (this.clickUnRegisterFn) {
      this.clickUnRegisterFn();
    }
    if (this.permissionMapSub) {
      this.permissionMapSub.unsubscribe();
    }
    this.vcr.clear();
  }

  handleComponentView(permMap) {
    if (permMap) {
      this.userPermissionMap = permMap;
      // checking for default true permission
      if (this._permissionId === UserPermission.alwaysShow
        || this.userPermissionMap[this._permissionId]
        || this.appUserPermissionIsDisabled) {
        this.createComponent();
        return;
      }
      if (Array.isArray(this._permissionId)) {
        this.checkAndCreatePermissionList();
        return;
      }
    }
  }

  // show component in view
  createComponent() {
    this.vcr.remove();
    if (this.appUserPermissionIsShow) {
      this.vcr.createEmbeddedView(this.tref);
      this.checkAndDisableComponent();
    }
  }

  // If any one of the permissions in the array is true, it should create the component.
  checkAndCreatePermissionList() {
    const isPermissible = this._permissionId.findIndex(permissionId => this.userPermissionMap[permissionId]);
    if (isPermissible >= 0) {
      this.createComponent();
    }
  }


  /**
   * Checks and disable component by adding the disabled class.
   */
  checkAndDisableComponent() {
    // Only to be disabled when disabled flag is true and permission is false. Otherwise it will not show.
    if (this.appUserPermissionIsDisabled && !this.userPermissionMap[this._permissionId]) {
      this.renderer.addClass(this.vcr.get(0)['rootNodes'][0], DISABLED_CLASS);
    }
  }
}
