import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import {
  ICustomerList,
  ICustomerPermission,
  IModelList,
  INotificationList,
  INotifiedUserInfo,
  IUserPermission,
  IUserInfo
} from 'reach/reach.interface';
import { CommonService } from './common.service';
import { AuthService } from 'reach/common/auth-module';
import { catchError, map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  public globalPermissions$: BehaviorSubject<any> = new BehaviorSubject(null);
  public clientDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  public lastUpdatedDates$: BehaviorSubject<any> = new BehaviorSubject(null);
  public modelList$: BehaviorSubject<any> = new BehaviorSubject(null);
  public customerMap$: BehaviorSubject<any> = new BehaviorSubject(null);




  public customersList = [];
  // Need to create UserService
  private userPermissions: IUserPermission;
  private userCustomers: Array<any> = [];

  constructor(private commonService: CommonService, public auth: AuthService) {
    if (this.auth.isAuthenticated) {
      this.getCustomerListForLoggedInUser()
        .subscribe((customers: ICustomerList[]) => {
          // this.customerMap$.subscribe((customerMapList: Array<any>) => {
          customers.forEach(customer => {
            this.userCustomers.push(customer.customerName);
            // console.log(customer.customerName);
            // if (customerMapList.indexOf(customer.customerName) !== -1) {
            // }
          });
          // console.log(this.userCustomers);
          // });
        });
      this.customerMap$.subscribe(res => {
        this.customersList = res;
        // console.log('this.customersList......', this.customersList);
      });
    }
  }

  static scrollToElement(id) {
    const el = document.getElementById(`${id}`);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  haveUserPermission(path, userPermissions) {
    return true;
  }

  public isCustomerUser() {
    if (this.userPermissions) {
      return (this.userPermissions.userType === 'customer' || this.userPermissions.userType === 'customer-admin');
    }
    return true;
  }

  getCustomerList(): Observable<ICustomerList[]> {
    return this.getCustomerListForLoggedInUser();
  }

  getModelList(): Observable<IModelList[]> {
    const serviceUrl = `${CommonService.serverUrl}/modelinfo/models/`;
    return this.modelList$;
    // return this.commonService.get<IModelList[]>(serviceUrl).pipe(catchError(err => this.commonService.handleError(err)));
  }

  getNotifications(): Observable<INotificationList[]> {
    const serviceUrl = `${CommonService.serverUrl}/user-server/users/getunreadnotifications`;
    return this.commonService.get(serviceUrl);
    // .catch(err => this.commonService.handleError(err));
  }

  updateNotificationService(data) {
    const serviceUrl = `${CommonService.serverUrl}/user-server/users/updatenotification`;
    return this.commonService.put(serviceUrl, data).pipe(
      map((results) => {
        return results;
      }),
      catchError(err => this.commonService.handleError(err))
    );
    // .catch(err => this.commonService.handleError(err));
  }

  getNotificationInfo(data): Observable<INotifiedUserInfo[]> {
    const serviceUrl = `${CommonService.serverUrl}/user-server/admin/getuserid/${data.id}`;
    return this.commonService.get(serviceUrl, data);
  }

  getCustomerListForLoggedInUser(): Observable<ICustomerList[]> {
    return this.clientDetails$.pipe(
      map((e: IUserPermission) => {
        const customers: ICustomerList[] = [];
        if (e && e.activeCustomerList) {
          e.activeCustomerList.forEach(each => {
            customers.push({ 'customerName': each });
          });
        }
        return customers;
      }));
  }

  getAdminPermissionById(id: any): Observable<IUserPermission> {
    const serviceUrl = `${CommonService.serverUrl}/user-server/admin/getpermission/${id}`;
    return this.commonService.get(serviceUrl);
  }

  getCustomerAdminPermissionsById(id: any): Observable<ICustomerPermission> {
    const serviceUrl = `${CommonService.serverUrl}/user-server/customeradmin/getpermission/${id}`;
    return this.commonService.get(serviceUrl);
  }

  getUserInfoService(): Observable<IUserInfo> {
    const serviceUrl = `${CommonService.serverUrl}/user-server/aad/user/profile`;
    return this.commonService.get(serviceUrl).pipe(map((res: IUserInfo) => {
      res.userType = 'super-admin';
      return res;
    }));
  }

  customerListInterceptor(customers: any): any {
    if (this.userCustomers.length === 0) {
      this.getCustomerListForLoggedInUser().subscribe();
    }
    if (this.userCustomers.length > 0 && customers.length === 1 && customers[0][0] === 'ALL') {
      return [this.userCustomers];
    } else if (this.userCustomers.length > 0 && customers.length === 1 && customers[0] === 'ALL') {
      return this.userCustomers;
    } else {
      return [customers[0]];
    }
  }

  customerListInterceptorCP(customers: any): any {
    if (this.userCustomers.length === 0) {
      this.getCustomerListForLoggedInUser().subscribe();
    }
    if (this.userCustomers.length > 0 && customers.length === 1 && customers[0] === 'ALL') {
      return this.mapCustomerList(this.userCustomers);
    } else if (this.userCustomers.length > 0 && customers.length === 1 && customers[0] && customers[0][0] === 'ALL') {
      return [this.mapCustomerList(this.userCustomers)];
    } else if (typeof (customers) === 'object' && customers.length > 1) {
      return this.mapCustomerList(customers);
    } else {
      return Object.prototype.toString.call(customers[0]) === '[object Array]' ?
        [this.mapCustomerList(customers[0])] : [...this.mapCustomerList(customers)];
    }
  }

  mapCustomerList(customerList: Array<any>) {
    if (customerList && typeof (customerList) === 'object' && customerList.length) {
      let updatedCustomerList = [];
      customerList.forEach(element => {
        this.customersList.every((ele) => {
          if (element === ele.id) {
            updatedCustomerList = [...updatedCustomerList, ...ele.companies];
            return false;
          } else {
            return true;
          }
        });
      });
      return updatedCustomerList;
    } else {
      let updatedCustomerList = [];
      this.customersList.every((ele) => {
        if (customerList === ele.id) {
          updatedCustomerList = [...ele.companies];
          return false;
        } else {
          return true;
        }
      });
      return updatedCustomerList;
    }
  }
}
