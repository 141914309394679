import { Injectable } from '@angular/core';
// import from index avoided to prevent circular dependency.
import { CommonService } from '@reach/common/shared-module/services/common.service';
import { AppConfiguration } from 'reach/reach.configuration';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IscriptsReports, INotesList, ITask, ISpecificNote, ISingleLog, ISasTokenBe, IDeployedPackageList } from 'reach/reach.interface';

@Injectable()
export class ScriptsTabService {
  public scriptsUrl = `${AppConfiguration.iotUrl}/commandaction/script/id`;
  public reportsUrl = `${AppConfiguration.iotUrl}/commandaction/report/id`;
  public runScriptUrl = `${AppConfiguration.iotUrl}/agentcommandinvocation/id`;
  public previewUrl = `${AppConfiguration.iotUrl}/commandaction/downloadreport/id`;
  public noteUrl = `${AppConfiguration.iotUrl}/revnotes/`;
  public systemlogDownloadUrl = `${AppConfiguration.iotUrl}/task/downloadLog/`;
  public sasTokenUrl = `${AppConfiguration.iotUrl}/configxml/sastoken/id`;
  public deployedPackageListUrl = `${AppConfiguration.iotUrl}/packages/deployment/status/id`;
  public pullTaskUrl = `${AppConfiguration.iotUrl}/agentcommandinvocation/pull/id`;
  public getTasksUrl = `${AppConfiguration.iotUrl}/task/all/id`;

  constructor(private commonService: CommonService) { }

  createNote(noteDetails) {
    return this.commonService.post(
      this.noteUrl,
      noteDetails,
      { headers: CommonService.httpHeaders })
      .pipe(
        map((results) => {
          return results;
        }),
        catchError(err => this.commonService.handleError(err))
      );
  }

  getNotesList(deviceId): Observable<INotesList[]> {
    const serviceUrl = `${this.noteUrl}deviceId?deviceId=${deviceId}`;
    return this.commonService.get<any>(serviceUrl).pipe(
      map(res => {
        return res;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  deleteNote(id) {
    const serviceUrl = `${this.noteUrl}id` + `?noteId=${id}`;
    return this.commonService.delete(serviceUrl).pipe(
      map(res => {
        return res;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  getSpecificNotesData(id, deviceId): Observable<ISpecificNote> {
    const serviceUrl = `${this.noteUrl}id` + `?noteId=${id}`;
    return this.commonService.get<any>(serviceUrl).pipe(
      map(res => {
        return res;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  createNewCommentNote(newComment, id): Observable<ISpecificNote> {
    const serviceUrl = `${this.noteUrl}comment` + `?id=${id}`;
    return this.commonService.post(serviceUrl,
      {
        'ownerMailId': newComment.emailId,
        'jobTitle': newComment.jobTitle,
        'firstName': newComment.firstName,
        'lastName': newComment.lastName,
        'comment': newComment.comment
      },
      { headers: CommonService.httpHeaders })
      .pipe(
        map((results) => {
          return results;
        }),
        catchError(err => this.commonService.handleError(err))
      );
  }

  saveNote(editNoteDetails) {
    const serviceUrl = this.noteUrl;
    return this.commonService.put(serviceUrl,
      {
        'noteId': editNoteDetails.noteId,
        'message': editNoteDetails.message,
        'title': editNoteDetails.title
      },
      { headers: CommonService.httpHeaders })
      .pipe(
        map((results) => {
          return results;
        }),
        catchError(err => this.commonService.handleError(err))
      );
  }

  downloadTask(data): Observable<any> {
    return this.commonService.postResponseWithHeader(`${AppConfiguration.iotUrl}/task/downloadfile`, data,
      { responseType: 'text', observe: 'response' }).pipe(
        map(res => {
          return res;
        }),
        catchError(err => this.commonService.handleError(err))
      );
  }

  getPreviewReport(deviceId, reportName) {
    const serviceUrl = `${this.previewUrl}` + `?deviceId=${deviceId}&reportName=${reportName}`;
    return this.commonService.get<any>(serviceUrl, { responseType: 'text' }).pipe(
      map(res => {
        return res;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  getSystemLog(log: ISingleLog, deviceId) {
    const serviceUrl = `${this.systemlogDownloadUrl}${log.id}`;
    return this.commonService.get<any>(serviceUrl, { responseType: 'text' }).pipe(
      map(res => {
        return res;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  getScripts(deviceId?): Observable<IscriptsReports[]> {
    const serviceUrl = `${this.scriptsUrl}` + `?deviceId=${deviceId}`;
    return this.commonService.get<any>(serviceUrl).pipe(
      map(response => {
        return response;
      }),
      catchError(err => this.commonService.handleError(err))
    );
  }

  getReports(deviceId?): Observable<IscriptsReports[]> {
    const serviceUrl = `${this.reportsUrl}` + `?deviceId=${deviceId}`;
    return this.commonService.get<any>(serviceUrl).pipe(
      map(res => res),
      catchError(err => this.commonService.handleError(err))
    );
  }

  getTasks(deviceId: string): Observable<ITask[]> {
    return this.commonService.get<any>(`${this.getTasksUrl}?deviceId=${deviceId}`).pipe(
      map(res => res),
      catchError(err => this.commonService.handleError(err))
    );
  }

  pullTask(path, deviceId, serialNo): Observable<any> {
    // Changes made by Lakshit rawat. Requested by Dharmendra.
    return this.commonService.postResponseWithHeader(this.pullTaskUrl,
      {
        sourceFile: path,
        deviceId: deviceId,
        serialNo: serialNo
      }
    ).pipe(catchError(err => this.commonService.handleError(err)));
  }

  pushTask(formData): Observable<any> {
    return this.commonService.postResponseWithHeader(`${AppConfiguration.iotUrl}/configxml/uploadtaskfile/id`,
      formData,
      {
        response: 'text'
      }
    ).pipe(catchError(err => this.commonService.handleError(err)));
  }

  initiatePushTask(formData): Observable<any> {
    return this.commonService.postResponseWithHeader(`${AppConfiguration.iotUrl}/configxml/initiate/fileupload/id`,
      formData,
      {
        response: 'text'
      }
    ).pipe(catchError(err => this.commonService.handleError(err)));
  }

  runCommand(selectedCommands, isSingleCheckbox = false) {
    let serviceUrl;
    if (isSingleCheckbox) {
      serviceUrl = this.runScriptUrl + 's';
    } else {
      serviceUrl = this.runScriptUrl;
    }
    return this.commonService.post(serviceUrl,
      selectedCommands).pipe(
        catchError(err => this.commonService.handleError(err)));
  }

  getSasToken(deviceId: string, filename: string, fileSize: number): Observable<ISasTokenBe> {
    return this.commonService.get(`${this.sasTokenUrl}?deviceId=${deviceId}&fileName=${filename}&fileSize=${fileSize}`)
      .pipe(map((res: ISasTokenBe) => {
        return res;
      }),
        catchError(err => this.commonService.handleError(err)));
  }


  getDeployedPackageList(deviceId): Observable<IDeployedPackageList[]> {
    const serviceUrl = `${this.deployedPackageListUrl}?deviceId=${deviceId}`;
    return this.commonService.get<any>(serviceUrl).pipe(
      map(res => res),
      catchError(err => this.commonService.handleError(err))
    );
  }


}
