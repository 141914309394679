export enum UserPermission {
    // executive dashboard
    closedDispatchCasesAfterQa = '93',
    // dailyAlerts = '11',
    dashboardCard = '89',
    avgNumHrsSpentOnSiteExec = '18', // not using now
    onSiteTimeSpentExec = '19', // not using now
    casesByAresExec = '20', // not using now
    slaZoneExec = '21',  // not using now
    highFreqDispatchStoresExec = '22', // not using now
    numOfCasesCreatedHourlyExex = '23', // not using now
    cashFlowInnovationExec = '24', // not using now
    firstTimeFixExec = '25', // not using now

    // user admin
    addDelUser = '1',
    assignUserPermissions = '2',
    assignUserToGroups = '3',
    createGroup = '4',

    // application operations
    createPackage = '5',
    deployPackage = '6',
    revolutionAvailabilityCard = '13',
    revolutionAlertCard = '14',
    packageHistory = '99',
    packageList = '106',

    // app operations (rev details page)
    viewRevolutionDetailsPage = '98',
    deployedPackageList = '108',
    notes = '100',
    caseHistory = '29',
    systemLogs = '30',
    errorLog = '33',
    recentActivity = '94',
    transferFrom = '34',
    transferTo = '35',
    approverPage = '109',
    ftpConnection = '110',
    cancelDeployment = '116',

    // remote access
    remoteAccess = '90',
    remoteTransferTo = '36',
    remoteTransferFrom = '91',
    lowBandwidth = '111',
    immutableAuditTrail = '112',

    // scripts
    runScripts = '96',
    startFullDbBackup = '37',
    uploadBackupConf = '38',
    swInstallMain = '39',
    sysRestartRev = '40',
    sysRestartXfsRecycler = '41',
    sysTerminateRev = '42',
    sysTerminateXfsRecycler = '43',
    sysUploadStatusFile = '44',
    traceRevGbru = '45',
    sysRestartAll = '46',
    sysTerminateAll = '47',
    sysRebootSystem = '48',
    traceRev = '49',


    // run reports
    runReports = '95',
    repCashFundTranFinalization = '50',
    repCashFundTran = '51',
    repCashTrackingAllCsv = '52',
    repCashTrackInOutNetCsv = '53',
    repDetailDailyActivity = '55',
    repPalmScannerNoticeConsent = '56',
    repUploadAutoReports = '57',
    repRegisterLoansPickups = '58',
    repRegisterInOut = '59',
    repRegisterActivity = '60',
    repUserSetup = '61',
    repStoreSetup = '62',
    repSetup = '63',
    repCurrentContent = '64',
    repActiveUsers = '65',
    repBalance = '66',
    repCashAdv = '67',
    repCashPickup = '68',
    repCftByAcc = '69',
    repTransaction = '70',
    repRegistersOut = '71',
    repDailyRecapCsv = '72',
    repEOD = '73',
    repInOutNetCsv = '74',
    repInOutNet = '75',
    repOversAndShorts = '76',
    repEodCsv = '77',
    repErrors = '78',


    userExperienceCard = '7',
    viewInterventionDrivers = '8',
    reliabilityTree = '10',
    enableDailyAlerts = '15',
    enableDailyDashboard = '12',

    // KPI Permissions
    avgNumHrsSpentOnSite = '79',
    onSiteTimeSpent = '80',
    casesByArea = '81',
    slaZone = '82',
    highFreqDispatchStores = '83',
    numOfCasesCreatedHourly = '84',
    cashFlowInnovation = '85', // not using now
    firstTimeFix = '86',
    upTime = '87',


    // Sidenav Permissions
    adminPanel = '97',
    viewDailyDashboard = '16', // not using now
    viewDailyAlertsDashboard = '9',
    customerDashboard = '114',
    connectedHealth = '115',


    // Connected health Permissions
    interventionPerformers = '117',
    dispatchPerformers = '118',
    averageDailyNoteVolume = '119',

    // DUMMY
    alwaysShow = 'alwaysShow'
}


