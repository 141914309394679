import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { LoadingComponent } from './loading.component';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appLoading]'
})
export class LoadingDirective implements OnDestroy {

    @Output() spinnerTimeout = new EventEmitter<boolean>(false);

    loadingFactory: ComponentFactory<LoadingComponent>;
    loadingComponent: ComponentRef<LoadingComponent>;

    private hasView = false;
    private timeOutSubscription: Subscription;

    constructor(private templateRef: TemplateRef<any>,
                private vcRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {
        this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
    }

    @Input() set appLoading(loading: boolean) {
        this.vcRef.clear();

        this.removeTimeoutSubscription();

        if (loading) {
            this.vcRef.createEmbeddedView(this.templateRef);
        } else {
            this.loadingComponent = this.vcRef.createComponent(this.loadingFactory);
            this.timeOutSubscription = this.loadingComponent.instance.timeout.subscribe((timeout) => {
                this.spinnerTimeout.emit(timeout);
            });
        }
    }

    ngOnDestroy() {
        this.removeTimeoutSubscription();
    }

    removeTimeoutSubscription() {
        if (this.timeOutSubscription) {
            this.timeOutSubscription.unsubscribe();
        }
    }

}
