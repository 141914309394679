import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppConfiguration } from 'reach/reach.configuration';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any, time?: any): any {

    if (time === 'fullDate') {
      if (value === 0) {
        return 'N/A';
      } else {
        value = Number(value);
        return moment(value).local().format(AppConfiguration.global.fullDateAndTimeFormat);
      }
    } else if (time === 'utc') {
      value = Number(value);
      return moment(value).utc().format(AppConfiguration.global.fullDateAndTimeFormat);
    } else if (time === 'deploymentDiff') {
      let date: any = new Date(value);
      let current: any = new Date().getTime();
      let diff: any = ((current - date) / 1000);
      diff = Math.round(diff / (60 * 60));
      if (diff <= 23) {
        if (diff <= 1) {
          diff = diff + 'Hour';
        } else {
          diff = diff + 'Hours';
        }
      } else {
        diff = Math.round(diff / 24);
        if (diff <= 1) {
          diff = diff + ' Day';
        } else {
          diff = diff + ' Days';
        }
      }
      return diff;
    } else {
      if (typeof value === 'string') {
        let date = moment().format(AppConfiguration.global.apiTimeFormat[4]);
        if (date === value) {
          return 'Today';
        } else {
          const d = moment().subtract(1, 'days');
          let str = d.format(AppConfiguration.global.apiTimeFormat[4]);
          if (str === value) {
            return 'Yesterday';
          } else {
            return value;
          }
        }
      } else if (typeof value === 'number') {
        let date = moment(value).local().format('h:mm a');
        return date;
      }
    }
  }
}

