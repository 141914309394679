import { NgModule } from '@angular/core';
import { PackageListComponent } from './package-list/package-list.component';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { ScriptsTabService } from './scripts-tab.service';
import { SharedModule } from '../shared-module/shared.module';
import { ScriptsTabComponent } from './scripts-tab.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        PackageListComponent,
        TaskDialogComponent,
        ScriptsTabComponent
    ],
    exports: [
        ScriptsTabComponent,
    ],
    providers: [
        ScriptsTabService
    ],
    entryComponents: [TaskDialogComponent]
})
export class ScriptsTabModule { }
