import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListOperationsService {

  constructor() { }


  /**
   * Applys sort
   * @author Lakshit Rawat
   * @param list
   * @param type: asc or desc
   * @param field
   */
  applySort(list: Array<any> = [], type?: string, field?: string) {
    list.sort((a, b) => {
      if (a[field] === b[field]) {
        return 0;
      } else if (a[field] < b[field]) {
        return type === 'asc' ? -1 : 1;
      } else {
        return type === 'asc' ? 1 : -1;
      }
    });
  }

  clearFilters(dropdownList) {
    dropdownList.options._results.forEach(Option => {
      Option.deselect();
    });
  }

  calculateDifference(list1: Array<any>, list2: Array<any>, comparisonKey: string) {
    let uniqueResultOne = list1.filter(function (obj) {
      return !list2.some(function (obj2) {
        return obj[comparisonKey] === obj2[comparisonKey];
      });
    });

    // Find values that are in list2 but not in list1
    let uniqueResultTwo = list2.filter(function (obj) {
      return !list1.some(function (obj2) {
        return obj[comparisonKey] === obj2[comparisonKey];
      });
    });

    // Combine the two arrays of unique entries
    return uniqueResultOne.concat(uniqueResultTwo);
  }

}
