import { Pipe, PipeTransform } from '@angular/core';

/**
 * AlertStatusPipe
 * @desc removes repeated alert status and parenthesis
 */
@Pipe({
    name: 'alertStatus'
})
export class AlertStatusPipe implements PipeTransform {

    transform(value: any, searchName?: string, args?: any): any {
        // regex /\(([^()]*)\)/gi: capture anything that is enclosed in ()
        if (!value) {
            return [];
        }
        if (args) {
           if (!searchName) {
                return value;
            }
            let array = args.split(',');
            return value.filter(v => {
                searchName = searchName.toLowerCase();
               // array.every(element => {
                    if (v[array[0]]) {
                        const packageNameRes = v[array[0]].toLowerCase().includes(searchName);
                        if (packageNameRes) {
                            return packageNameRes;
                        }
                    }
               // });
            });
        } else {
            const res = value.match(/\(([^()]*)\)/gi);
            return res && res.length > 0 ? res[0].substring(1, res[0].length - 1) : '';
        }
    }
}
