export const environment = {
    origin: 'http://localhost:4200/',
    production: false,
    qual: false,
    environment: 'dev',
    authUrl: 'http://35.202.129.131:8888/auth',
    serviceUrl: 'http://35.202.129.131:8888/iot/demo/api',
    iotUrl: 'http://35.202.129.131:8888/iot/demo/iot/api',
    ftpUrl: 'https://devserviceproxy.rrs360.com',
    remoteConnection: {
        serviceHttpUrl: 'http://localhost:9898/guacamole-remote-client',
        serviceWsUrl: 'ws://localhost:9898/guacamole-remote-client',
        serviceHost: 'localhost:9898',
        port: 443
    }
};